import React from "react";
import { Menu, Spin, Typography, Input, Checkbox } from "antd";
import { LoadingOutlined, TagTwoTone } from "@ant-design/icons";

const { Text } = Typography;
const { Search } = Input;
const FilterSearchPaginate = ({
  datasource,
  filter_key,
  selectedFilters,
  onFilterSearch,
  onFilterPaginate,
  loader,
  type,
  searchPlaceholder,
  searchValue,
  req_filter,
  switchValue,
  onSelect,
  handleNoTag,
  noTagToggle,
}) => {
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  let loaderItem =
    (loader === true || loader === "LOADING") &&
    getItem(
      <div
        key="loader"
        style={{
          position: "absolute",
          bottom: "8px",
          right: "8px",
          padding: "5px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        className="menu-items-list"
      >
        <div>
          <Spin indicator={<LoadingOutlined spin />} />
        </div>
      </div>,
      null,
      null,
    );
  let itemsList =
    datasource && datasource?.length
      ? [
          ...datasource?.map((data, index) =>
            getItem(
              <>
                {type === "tags" ||
                  (type === "noTag" && (
                    <Text
                      className="text-camelCase"
                      style={{ fontWeight: "400" }}
                      disabled={noTagToggle ? true : false}
                    >
                      <TagTwoTone twoToneColor={`${data.color_code}`} />
                      <Text style={{ fontWeight: "400" }}>{data?.name}</Text>
                    </Text>
                  )) ||
                  (type === "object" && (
                    <Text
                      className="text-camelCase"
                      style={{ fontWeight: "400" }}
                    >
                      {data?.name?.toLowerCase()}
                    </Text>
                  )) ||
                  (type === "journal" && (
                    <Text
                      className="text-camelCase"
                      style={{ fontWeight: "400" }}
                    >
                      {data?.number} ({data?.date_of_publication})
                    </Text>
                  )) || (
                    <Text style={{ fontWeight: "400" }}>
                      {data ? data : ""}
                    </Text>
                  )}
              </>,
              ((type === "object" || type === "tags" || type === "noTag") &&
                data?.id) ||
                (type === "journal" && data?.number) ||
                data,
              null,
            ),
          ),
          loaderItem,
        ]
      : [loaderItem];

  return (
    <div style={{ width: 450 }}>
      <div style={{ padding: "10px", borderBottom: "1px solid #E9E9E9" }}>
        <Search
          placeholder={searchPlaceholder ? searchPlaceholder : "Search"}
          value={searchValue}
          onChange={(event) => {
            onFilterSearch(req_filter, event.target.value);
          }}
          allowClear
        />
        {type === "noTag" ? (
          <Checkbox
            style={{ padding: "10px", marginLeft: "5px" }}
            checked={noTagToggle}
            onChange={() => {
              switchValue
                ? onSelect(
                    "selectedFilters",
                    switchValue,
                    "trademarkNOtags",
                    noTagToggle ? [] : ["no_tag"],
                  )
                : onSelect(
                    "selectedFilters",
                    "trademarkNOtags",
                    noTagToggle ? [] : ["no_tag"],
                  );
              handleNoTag();
            }}
          >
            <Text className="text-camelCase" style={{ fontWeight: "400" }}>
              {" "}
              No Tag{" "}
            </Text>
          </Checkbox>
        ) : (
          ""
        )}
      </div>
      <Menu
        onScroll={(x) => {
          const { scrollTop, scrollHeight, clientHeight } = x.target;
          if (scrollTop + clientHeight >= scrollHeight - 20) {
            onFilterPaginate?.();
            // This will be triggered after hitting the last element.
            // API call should be made here while implementing pagination.
          }
        }}
        disabled={noTagToggle ? true : false}
        multiple={true}
        selectable
        items={itemsList}
        selectedKeys={selectedFilters?.[filter_key]}
        onSelect={(event) =>
          switchValue
            ? onSelect(
                "selectedFilters",
                switchValue,
                filter_key,
                event.selectedKeys,
              )
            : onSelect("selectedFilters", filter_key, event.selectedKeys)
        }
        onDeselect={(event) =>
          switchValue
            ? onSelect(
                "selectedFilters",
                switchValue,
                filter_key,
                event.selectedKeys,
              )
            : onSelect("selectedFilters", filter_key, event.selectedKeys)
        }
      />
    </div>
  );
};

export default FilterSearchPaginate;

// Sample ONfilter Search

// handleSearchDebounced = debounce(function (required_filter, filter_contains_term) {
//   this.fetchFilters(required_filter, filter_contains_term, 1);
// }, 500);

// onFilterSearch = (required_filter, filter_contains_term) => {

//   this.setState({
//     isSearching: true,
//     filterConfig: {
//       ...this.state?.filterConfig,
//       [`${required_filter}`]: {
//         ...this.state?.filterConfig[`${required_filter}`],
//         filter_contains_term: filter_contains_term,
//         page: 1,
//       },
//     },
//   });

//   this.handleSearchDebounced(required_filter, filter_contains_term);
// };

//Sample onFilterPaginate

// onFilterPaginate = (required_filter, filter_contains_term, page) => {
//   const filterObj = this.state.changeSwitch
//     ? this.props.pseudoTMState?.filters
//     : this.props.totalTMState?.filters;
//   const isFilterLoading = this.state.changeSwitch
//     ? this.props.pseudoTMState?.filters?.filterStatus
//     : this.props.totalTMState?.filters?.filterStatus;

//   if (filterObj.notesFilterStatus !== "LOADING") {
//     const page_size = 100;
//     let total_pages = 0;

//     let count = 0;

//     this.state.filterKeys.forEach((key) => {
//       if (required_filter === key) {
//         count = filterObj[`${key}FilterDataCount`];
//       }
//     });

//     if (count !== undefined && count !== null && count > 0) {
//       total_pages = Math.ceil(count / page_size);
//     }
//     if (page <= total_pages && isFilterLoading !== "LOADING") {
//       this.setState({
//         filterConfig: {
//           ...this.state?.filterConfig,
//           [`${required_filter}`]: {
//             ...this.state?.filterConfig[`${required_filter}`],
//             page,
//           },
//         },
//       });
//       this.fetchFilters(required_filter, filter_contains_term, page);
//     }
//   }
// };
