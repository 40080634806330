import { GET_CALENDAR, REMOVE_CALENDAR , SET_STORE} from "./types";
import { MGR_RT_URL } from "../../../../configs/rootConfigs";
import Axios from "axios";
import { checkAuthorisation } from "../../commonActions/actions";
import { LOADING , SUCCESS, ERROR} from "../../../../constants/commonConstants/generalConstants";


export function getCalendar({ date,required_field}){
    

    return async (dispatch) => {
        dispatch({ type: GET_CALENDAR, status: LOADING,
            date:date,
            key:required_field  });
        try {
            
            let response;
            response = await Axios.get(`${MGR_RT_URL}/calendar_dashboard/`, {
                params: {
                    date:date,
                    required_field: required_field,
                },
                headers: { Authorization: 'Token ' + localStorage.getItem('token') }
            });
            dispatch({
                type: GET_CALENDAR,
                status: SUCCESS,
                payload: response?.data,  
                key:required_field             
            });

        }
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: GET_CALENDAR, status: ERROR,key:required_field })
        }
    }

}

export function removeCalendar(key){
    

    return async (dispatch) => {
        try {
            dispatch({
                type: REMOVE_CALENDAR,
                status: SUCCESS,
                payload: key,
               
            });

        }
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: REMOVE_CALENDAR, status: ERROR })
        }
  
}
}

//--------Manager: Set State Client Email --------
export function setStoreData(newState) {
    return (dispatch) => {
      dispatch({ type: SET_STORE, payload: newState });
    };
  }