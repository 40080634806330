import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { FixedSizeList } from "react-window";

import {
  DownOutlined,
  FileAddFilled,
  MinusCircleOutlined,
  MinusSquareFilled,
  PlusSquareFilled,
  UnorderedListOutlined,
} from "@ant-design/icons";

import {
  Row,
  Col,
  Pagination,
  Typography,
  Button,
  Input,
  Checkbox,
  Cascader,
  Dropdown,
  Menu,
  Radio,
  Tooltip,
  notification,
  message,
  Badge,
  Empty,
  Spin,
  Popover,
  Drawer,
  Alert,
  Image,
} from "antd";
import introJs from "intro.js";

//-------- ACTIONS--------
import {
  getReport,
  getReportDetail,
  createReport,
  updateReportMark,
  deleteReport,
} from "../../../redux/actions/searchActions/ReportAction";

//--------IP-SUITE CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";
import {
  changeStateValue_L0,
  changeStateValue_L1,
  changeStateValue_L2,
} from "../../../functions/commonFunctions/generalFunctions";
import {
  checkValue,
  checkNA,
} from "../../../functions/commonFunctions/checkValueFunctions";
import {
  Report_defaultPrimarySelectedFilters,
  Report_defaultPrimarySorting,
  Report_defaultSecondaryFiltersVisibility,
  Report_defaultSecondarySelectedFilters,
  Report_defaultSecondaryFiltersSearchTerms,
  Report_defaultSecondarySorting,
  Report_defaultCustomReportData,
  Report_defaultInitialReport,
} from "../../../constants/searchConstants/reportConstants";

import DateRangePicker from "../../../functions/commonFunctions/dateRangePicker";
import GeneralMarkCard from "../../commonComponents/generalCards/GeneralMarkCard";
import CreateReport from "../subComponents/createReport";
import { logOut } from "../../../redux/actions/commonActions/actions";
import { RM_GET_REPORT_AVAILABILITY } from "../../../redux/actions/searchActions/types";
import {
  getTrademarkReportStatus,
  patchTrademarkReport,
} from "../../../redux/actions/searchActions/trademarksSearchActions";
import { debounce } from "lodash";
import { postReport } from "../../../redux/actions/searchActions/commonActions";

const { Text, Title } = Typography;
const { Search } = Input;

class ReportManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      range1: null,
      range2: null,
      changeSwitch: "mark",
      drawersVisibility: {
        createReport: false,
        addToReport: false,
      },
      customReportData: {
        mark: Report_defaultCustomReportData,
        proprietor: Report_defaultCustomReportData,
        ipIndia: Report_defaultCustomReportData,
        imageSearch: Report_defaultCustomReportData,
      },
      markId: {
        mark: false,
        proprietor: false,
        ipIndia: false,
        imageSearch: false,
      },
      expandRecord: {
        mark: false,
        proprietor: false,
        ipIndia: false,
        imageSearch: false,
      },
      selectedView: {
        mark: "card",
        proprietor: "card",
        ipIndia: "card",
        imageSearch: "card",
      },
      primarySelectedFilters: {
        mark: this.props.markReportAppliedFilters,
        proprietor: this.props.proprietorReportAppliedFilters,
        ipIndia: this.props.proprietorReportAppliedFilters,
        imageSearch: this.props.proprietorReportAppliedFilters,
      },
      primarySorting: {
        mark: this.props.markReportAppliedSorting,
        proprietor: this.props.proprietorReportAppliedSorting,
        ipIndia: this.props.proprietorReportAppliedSorting,
        imageSearch: this.props.proprietorReportAppliedSorting,
      },
      primaryPageNumber: {
        mark: this.props.markReportPageNumber,
        proprietor: this.props.proprietorReportPageNumber,
        ipIndia: this.props.proprietorReportPageNumber,
        imageSearch: this.props.proprietorReportPageNumber,
      },
      secondaryFiltersVisibility: {
        mark: Report_defaultSecondaryFiltersVisibility,
        proprietor: Report_defaultSecondaryFiltersVisibility,
        ipIndia: Report_defaultSecondaryFiltersVisibility,
        imageSearch: Report_defaultSecondaryFiltersVisibility,
      },
      secondarySelectedFilters: {
        mark: this.props.markReportDetailAppliedFilters,
        proprietor: this.props.proprietorReportDetailAppliedFilters,
        ipIndia: this.props.proprietorReportDetailAppliedFilters,
        imageSearch: this.props.proprietorReportDetailAppliedFilters,
      },
      secondaryFiltersSearchTerms: {
        mark: Report_defaultSecondaryFiltersSearchTerms,
        proprietor: Report_defaultSecondaryFiltersSearchTerms,
        ipIndia: Report_defaultSecondaryFiltersSearchTerms,
        imageSearch: Report_defaultSecondaryFiltersSearchTerms,
      },
      secondarySorting: {
        mark: this.props.markReportDetailAppliedSorting,
        proprietor: this.props.proprietorReportDetailAppliedSorting,
        ipIndia: this.props.proprietorReportDetailAppliedSorting,
        imageSearch: this.props.proprietorReportDetailAppliedSorting,
      },
      secondaryPageNumber: {
        mark: this.props.markReportDetailPageNumber,
        proprietor: this.props.proprietorReportDetailPageNumber,
        ipIndia: this.props.proprietorReportDetailPageNumber,
        imageSearch: this.props.proprietorReportDetailPageNumber,
      },
      tableDescriptionRowsVisibility: false,
      reportFile: false,
    };
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
    this.changeStateValue_L2 = changeStateValue_L2.bind(this);

    this.handleConfirmReport_Debounced = debounce(
      this.handleConfirmReport,
      400,
    );
    this.setDate1 = this.setDate1.bind(this);
    this.setDate2 = this.setDate2.bind(this);
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.getReport({
        report_source: "MARK",
        filterFlag: false,
        filters: Report_defaultPrimarySelectedFilters,
        sortFlag: false,
        sorting: Report_defaultPrimarySorting,
        check: false,
        files: false,
        pageNumber: 1,
      });

      this.props.getReport({
        report_source: "IPINDIA",
        filterFlag: false,
        filters: Report_defaultPrimarySelectedFilters,
        sortFlag: false,
        sorting: Report_defaultPrimarySorting,
        check: false,
        files: false,
        pageNumber: 1,
      });

      await this.props.getReport({
        report_source: "PROPRIETOR",
        filterFlag: false,
        filters: Report_defaultPrimarySelectedFilters,
        sortFlag: false,
        sorting: Report_defaultPrimarySorting,
        check: false,
        files: false,
        pageNumber: 1,
      });

      await this.props.getReport({
        report_source: "IMAGE_SEARCH",
        filterFlag: false,
        filters: Report_defaultPrimarySelectedFilters,
        sortFlag: false,
        sorting: Report_defaultPrimarySorting,
        check: false,
        files: false,
        pageNumber: 1,
      });

      try {
        document.cookie
          .split("; ")
          .find((row) => row.startsWith("reportManagementTour"));
      } catch (err) {
        introJs().start();
        document.cookie = "reportManagementTour=false";
      }
    } else {
      this.props.logOut();
    }
  }

  async handleConfirmReport() {
    let response = null;
    let { changeSwitch } = this.state;
    let expandRecord = this.state.expandRecord[changeSwitch];
    let mark_id = this.state.drawersVisibility.addToReport;

    switch (this.props.reportAvailabilityStatus) {
      case SUCCESS:
        this.changeStateValue_L1("drawersVisibility", "addToReport", false);
        if (!this.props.reportAvailability.id) {
          response = await this.props.postReport({
            report_source: "MARK",
            report_type: this.state.customReportData[changeSwitch].report_type,
            mark_id,
            selected_mark_id_list:
              this.state.customReportData[changeSwitch].data[expandRecord]
                .realSelectedMark,
          });

          if (response.status === SUCCESS) {
            notification["success"]({
              message: "Report Queued",
              description: "The report has been queued successfully.",
            });
          }
        } else {
          if (this.props.reportAvailability.available) {
            response = await this.props.patchTrademarkReport(
              {
                report_source: "MARK",
                operation: this.state.customReportData[changeSwitch].operation,
                report_type:
                  this.state.customReportData[changeSwitch].report_type,
                selected_mark_id_list:
                  this.state.customReportData[changeSwitch].data[expandRecord]
                    .realSelectedMark,
              },
              this.props.reportAvailability.id,
            );

            if (response.status === SUCCESS) {
              notification["success"]({
                message: "Report Queued",
                description: "The report has been queued successfully.",
              });
            }
          } else {
            notification["warning"]({
              message: "Report Under Process",
              description:
                "The report is already under process. Kindly, wait for its completion",
            });
          }
        }
        break;

      case LOADING:
        break;

      default:
        break;
    }
  }

  handleApplyPrimaryFilters(searchInput) {
    const { changeSwitch } = this.state;
    const primarySelectedFilters =
      this.state.primarySelectedFilters[changeSwitch];
    const primarySorting = this.props[`${changeSwitch}ReportAppliedSorting`];
    if (searchInput !== primarySelectedFilters?.containsTerm) {
      this.props.getReport({
        report_source:
          changeSwitch === "imageSearch"
            ? "IMAGE_SEARCH"
            : changeSwitch.toUpperCase(),
        filterFlag: true,
        filters: {
          ...primarySelectedFilters,
          containsTerm: searchInput,
        },
        sortFlag: primarySorting.sortFlag,
        sorting: primarySorting,
        check: false,
        files: false,
      });
      this.setState({
        primarySelectedFilters: {
          ...this.state.primarySelectedFilters,
          [changeSwitch]: {
            ...this.state.primarySelectedFilters[changeSwitch],
            containsTerm: searchInput,
            filterFlag: true,
          },
        },
      });
    } else {
      message.error("please enter search value!");
    }
  }

  handleResetPrimaryFilters() {
    const { changeSwitch } = this.state;
    const { containsTerm } = this.state.primarySelectedFilters[changeSwitch];
    const primarySorting = this.props[`${changeSwitch}ReportAppliedSorting`];

    if (containsTerm.length > 0) {
      this.props.getReport({
        report_source:
          changeSwitch === "imageSearch"
            ? "IMAGE_SEARCH"
            : changeSwitch.toUpperCase(),
        filterFlag: false,
        filters: Report_defaultPrimarySelectedFilters,
        sortFlag: primarySorting.sortFlag,
        sorting: primarySorting,
        check: false,
        files: false,
      });
      this.setState({
        primarySelectedFilters: {
          ...this.state.primarySelectedFilters,
          [changeSwitch]: Report_defaultPrimarySelectedFilters,
        },
      });
    } else {
      message.error("No Filter Applied");
    }
  }

  handleApplyPrimarySorting(sortValue) {
    const { changeSwitch } = this.state;
    const primarySelectedFilters =
      this.props[`${changeSwitch}ReportAppliedFilters`];
    const primarySorting = this.state.primarySorting[changeSwitch];
    const primaryPageNumber = this.props[`${changeSwitch}ReportPageNumber`];
    if (sortValue.length !== primarySorting.sortValue) {
      if (sortValue[0] === "CLEAR") {
        this.props.getReport({
          report_source:
            changeSwitch === "imageSearch"
              ? "IMAGE_SEARCH"
              : changeSwitch.toUpperCase(),
          filterFlag: primarySelectedFilters
            ? primarySelectedFilters.filterFlag
            : false,
          filters: primarySelectedFilters,
          sortFlag: Report_defaultPrimarySorting.sortFlag,
          sorting: Report_defaultPrimarySorting,
          check: false,
          files: false,
          pageNumber: primaryPageNumber,
        });
        this.setState({
          primarySorting: {
            ...this.state.primarySorting,
            [changeSwitch]: Report_defaultPrimarySorting,
          },
        });
      } else {
        this.props.getReport({
          report_source:
            changeSwitch === "imageSearch"
              ? "IMAGE_SEARCH"
              : changeSwitch.toUpperCase(),
          filterFlag: primarySelectedFilters
            ? primarySelectedFilters.filterFlag
            : false,
          filters: primarySelectedFilters,
          sortFlag: true,
          sorting: {
            ...primarySorting,
            sortType: sortValue[0],
            sortOn: sortValue[1],
          },
          check: false,
          files: false,
          pageNumber: primaryPageNumber,
        });
        this.setState({
          primarySorting: {
            ...this.state.primarySorting,
            [changeSwitch]: {
              ...this.state.primarySorting[changeSwitch],
              sortType: sortValue[0],
              sortOn: sortValue[1],
              sortFlag: true,
              sortValue: sortValue,
            },
          },
        });
      }
    } else {
      message.error("please select value!");
    }
  }

  async handleDeleteReport() {
    const { changeSwitch } = this.state;
    const customReportData = this.state.customReportData[changeSwitch];
    const primarySelectedFilters =
      this.props[`${changeSwitch}ReportAppliedFilters`];
    const primarySorting = this.props[`${changeSwitch}ReportAppliedSorting`];

    await this.props.deleteReport({
      report_source:
        changeSwitch === "imageSearch"
          ? "IMAGE_SEARCH"
          : changeSwitch.toUpperCase(),
      report_id_list: customReportData.selectedMark,
    });
    notification[this.props.deleteReportStatus.toLowerCase()]({
      message: "Report Deleted",
      description: this.props.deleteReportMessage,
    });
    this.props.getReport({
      check: false,
      files: false,
      report_source:
        changeSwitch === "imageSearch"
          ? "IMAGE_SEARCH"
          : changeSwitch.toUpperCase(),
      filterFlag: primarySelectedFilters
        ? primarySelectedFilters.filterFlag
        : false,
      filters: primarySelectedFilters,
      sortFlag: primarySorting.sortFlag,
      sorting: primarySorting,
    });
    this.setState({
      customReportData: {
        ...this.customReportData,
        [changeSwitch]: Report_defaultCustomReportData,
      },
      visible: false,
    });
  }

  handleClearSelectedReport(expandRecord) {
    const { changeSwitch } = this.state;
    if (expandRecord) {
      this.setState({
        customReportData: {
          ...this.state.customReportData,
          [changeSwitch]: {
            ...this.state.customReportData[changeSwitch],
            ...Report_defaultCustomReportData,
            data: {
              ...this.state.customReportData[changeSwitch].data,
              [expandRecord]: {
                ...this.state.customReportData[changeSwitch].data[
                  `${expandRecord}`
                ],
                ...Report_defaultInitialReport,
                filter_flag:
                  this.state.customReportData[changeSwitch].data[expandRecord]
                    .filter_flag,
                filters:
                  this.state.customReportData[changeSwitch].data[expandRecord]
                    .filters,
                sort_flag:
                  this.state.customReportData[changeSwitch].data[expandRecord]
                    .sort_flag,
                sort: this.state.customReportData[changeSwitch].data[
                  expandRecord
                ].sort,
                markCount:
                  this.state.customReportData[changeSwitch].data[expandRecord]
                    .markCount,
              },
            },
          },
        },
      });
    } else {
      this.setState({
        customReportData: {
          ...this.state.customReportData,
          [changeSwitch]: Report_defaultCustomReportData,
        },
      });
    }
  }

  handlePrimarySelectedMark(event, marksId, marksCount) {
    const { changeSwitch } = this.state;
    const customReportData = this.state.customReportData[changeSwitch];
    if (!customReportData.data[`${marksId}`]) {
      this.setState({
        customReportData: {
          ...this.state.customReportData,
          [changeSwitch]: {
            ...this.state.customReportData[changeSwitch],
            data: {
              ...this.state.customReportData[changeSwitch].data,
              [marksId]: {
                ...this.state.customReportData[changeSwitch].data[`${marksId}`],
                ...Report_defaultInitialReport,
                select_all: event.target.checked,
                filteredMarkCount: marksCount,
              },
            },
            selectAllCount:
              this.state.customReportData[changeSwitch].selectAllCount + 1,
            markCount:
              this.state.customReportData[changeSwitch].markCount +
              parseInt(marksCount),
            selectedMark: [
              ...this.state.customReportData[changeSwitch].selectedMark,
              marksId,
            ],
          },
        },
        markId: {
          ...this.state.markId,
          [changeSwitch]: marksId,
        },
      });
    } else {
      this.setState({
        customReportData: {
          ...this.state.customReportData,
          [changeSwitch]: {
            ...this.state.customReportData[changeSwitch],
            data: {
              ...this.state.customReportData[changeSwitch].data,
              [marksId]: {
                ...this.state.customReportData[changeSwitch].data[`${marksId}`],
                select_all: event.target.checked,
                filteredMarkCount: marksCount,
              },
            },
            selectAllCount: event.target.checked
              ? this.state.customReportData[changeSwitch].selectAllCount + 1
              : this.state.customReportData[changeSwitch].selectAllCount - 1,
            markCount: event.target.checked
              ? this.state.customReportData[changeSwitch].markCount +
                parseInt(marksCount)
              : this.state.customReportData[changeSwitch].markCount -
                parseInt(marksCount),
            selectedMark: event.target.checked
              ? [
                  ...this.state.customReportData[changeSwitch].selectedMark,
                  marksId,
                ]
              : this.state.customReportData[changeSwitch].selectedMark.filter(
                  (item) => item !== marksId,
                ),
          },
        },
        markId: {
          ...this.state.markId,
          [changeSwitch]: marksId,
        },
      });
    }
  }

  onVisibleRealTimePopover(visible, markId) {
    const { changeSwitch } = this.state;
    const secondarySelectedFilters =
      this.props[`${changeSwitch}ReportDetailAppliedFilters`];
    const secondarySorting =
      this.props[`${changeSwitch}ReportDetailAppliedSorting`];

    this.setState({
      realTimeReportvisible: visible,
      markId: {
        ...this.state.markId,
        [changeSwitch]: markId,
      },
      customReportData: {
        ...this.state.customReportData,
        [changeSwitch]: {
          ...this.state.customReportData[changeSwitch],
          data: {
            ...this.state.customReportData[changeSwitch].data,
            [markId]: {
              ...this.state.customReportData[changeSwitch].data[`${markId}`],
              filter_flag: secondarySelectedFilters
                ? secondarySelectedFilters?.filterFlag
                : false,
              filters: {
                ...secondarySelectedFilters,
              },
              sort_flag: secondarySorting?.sortFlag,
              sort: {
                ...secondarySorting,
              },
            },
          },
          id: markId,
          report_source:
            changeSwitch === "imageSearch"
              ? "IMAGE_SEARCH"
              : changeSwitch.toUpperCase(),
          operation: "REALTIME",
          report_file: false,
          report_file_name: null,
          report_file_types: null,
        },
      },
    });
  }

  async handleRealTimeReport(markId) {
    const { changeSwitch } = this.state;
    const customReportData = this.state.customReportData[changeSwitch];

    const primarySelectedFilters =
      this.props[`${changeSwitch}ReportAppliedFilters`];
    const primarySorting = this.props[`${changeSwitch}ReportAppliedSorting`];

    let filter_data = {};
    let sort_data = {};
    if (customReportData.data[`${markId}`].filter_flag) {
      filter_data = {
        filter_class: customReportData.data[`${markId}`].filters.filterClasses,
        filter_journal_number:
          customReportData.data[`${markId}`].filters.filterJournalNumber,
        filter_state: customReportData.data[`${markId}`].filters.filterState,
        filter_office: customReportData.data[`${markId}`].filters.filterOffice,
        filter_trademark_category:
          customReportData.data[`${markId}`].filters.filterCategory,
        filter_trademark_type:
          customReportData.data[`${markId}`].filters.filterType,
        filter_logic_type:
          customReportData.data[`${markId}`].filters.filterLogicType,
        filter_trademark_status:
          customReportData.data[`${markId}`].filters.filterStatus,
        filter_proprietor:
          customReportData.data[`${markId}`].filters.filterProprietor,
        filter_proprietor_name:
          customReportData.data[`${markId}`].filters.filterProprietorName,
        filter_application_date:
          customReportData.data[`${markId}`].filters.date_of_application,
        filter_date_of_usage:
          customReportData.data[`${markId}`].filters.date_of_usage,
        filter_date_of_usage_proposed:
          customReportData.data[`${markId}`].filters.date_of_usage_proposed,
        filter_contains_term:
          customReportData.data[`${markId}`].filters.containsTerm,
      };
    } else {
      filter_data = {};
    }

    if (customReportData.data[`${markId}`].sort_flag) {
      sort_data = {
        sort_type: customReportData.data[`${markId}`].sort.sortType,
        sort_on: customReportData.data[`${markId}`].sort.sortOn,
      };
    } else {
      sort_data = {};
    }

    await this.props.createReport({
      id: markId,
      report_source: customReportData.report_source,
      operation: customReportData.operation,
      report_file: customReportData.report_file,
      report_file_name: customReportData.report_file_name,
      report_file_types: customReportData.report_file_types,
      select_all: customReportData.data[`${markId}`].select_all,
      selected_mark_id_list: customReportData.data[`${markId}`].selectedMark,
      sort_flag: customReportData.data[`${markId}`].sort_flag,
      sort: sort_data,
      filter_flag: customReportData.data[`${markId}`].filter_flag,
      filters: filter_data,
    });

    // notification[this.props.createSearchReportStatus.toLowerCase()]({
    //     message: 'Queued Realtime Report',
    //     description: 'Your Report is queued for real time and will be sent to you via e-mail. You can also visit the Reports section, once generated.',
    // });
    this.props.getReport({
      check: false,
      files: false,
      report_source:
        changeSwitch === "imageSearch"
          ? "IMAGE_SEARCH"
          : changeSwitch.toUpperCase(),
      filterFlag: primarySelectedFilters
        ? primarySelectedFilters.filterFlag
        : false,
      filters: primarySelectedFilters,
      sortFlag: primarySorting.sortFlag,
      sorting: primarySorting,
    });

    this.setState({
      customReportData: {
        ...this.state.customReportData,
        [changeSwitch]: {
          ...this.state.customReportData[changeSwitch],
          ...Report_defaultCustomReportData,
          data: {
            ...this.state.customReportData[changeSwitch].data,
            [markId]: {
              ...this.state.customReportData[changeSwitch].data[`${markId}`],
              markCount: 0,
              select_all: false,
              selectedMark: [],
              realSelectedMark: [],
              filteredMarkCount: 0,
            },
          },
        },
      },
      expandRecord: {
        [changeSwitch]: false,
      },
      realTimeReportvisible: false,
    });
  }

  async handleExpandRecord(expandRecord) {
    const { changeSwitch } = this.state;
    const customReportData = this.state.customReportData[changeSwitch];
    const secondaryPageNumber =
      this.props[`${changeSwitch}ReportDetailPageNumber`];

    if (expandRecord === false) {
      this.setState({
        expandRecord: {
          [changeSwitch]: false,
        },
      });
    } else {
      if (expandRecord.available) {
        if (!customReportData.data[`${expandRecord.id}`]) {
          this.setState({
            secondarySelectedFilters: {
              ...this.state.secondarySelectedFilters,
              [changeSwitch]: Report_defaultSecondarySelectedFilters,
            },
            secondarySorting: {
              ...this.state.secondarySorting,
              [changeSwitch]: Report_defaultSecondarySorting,
            },
            customReportData: {
              ...this.state.customReportData,
              [changeSwitch]: {
                ...this.state.customReportData[changeSwitch],
                data: {
                  ...this.state.customReportData[changeSwitch].data,
                  [expandRecord.id]: {
                    ...this.state.customReportData[changeSwitch].data[
                      `${expandRecord.id}`
                    ],
                    ...Report_defaultInitialReport,
                    filteredMarkCount: expandRecord.marks_count,
                  },
                },
              },
            },
          });
          this.props.getReportDetail({
            id: expandRecord.id,
            report_source:
              changeSwitch === "imageSearch"
                ? "IMAGE_SEARCH"
                : changeSwitch.toUpperCase(),
            filterFlag: false,
            filters: Report_defaultSecondarySelectedFilters,
            sortFlag: false,
            sorting: Report_defaultSecondarySorting,
            pageNumber: 1,
          });
        } else {
          await this.props.getReportDetail({
            id: expandRecord.id,
            report_source:
              changeSwitch === "imageSearch"
                ? "IMAGE_SEARCH"
                : changeSwitch.toUpperCase(),
            filterFlag: customReportData.data[`${expandRecord.id}`].filter_flag,
            filters: {
              ...customReportData,
              filterClasses:
                customReportData.data[`${expandRecord.id}`].filters
                  .filterClasses,
              filterState:
                customReportData.data[`${expandRecord.id}`].filters.filterState,
              filterOffice:
                customReportData.data[`${expandRecord.id}`].filters
                  .filterOffice,
              filterCategory:
                customReportData.data[`${expandRecord.id}`].filters
                  .filterCategory,
              filterType:
                customReportData.data[`${expandRecord.id}`].filters.filterType,
              filterStatus:
                customReportData.data[`${expandRecord.id}`].filters
                  .filterStatus,
              filterProprietor:
                customReportData.data[`${expandRecord.id}`].filters
                  .filterProprietor,
              date_of_application:
                customReportData.data[`${expandRecord.id}`].filters
                  .date_of_application,
              date_of_usage:
                customReportData.data[`${expandRecord.id}`].filters
                  .date_of_usage,
              date_of_usage_proposed:
                customReportData.data[`${expandRecord.id}`].filters
                  .date_of_usage_proposed,
              containsTerm:
                customReportData.data[`${expandRecord.id}`].filters
                  .containsTerm,
            },
            sortFlag: customReportData.data[`${expandRecord.id}`].sort_flag,
            sorting: {
              ...customReportData,
              sortType:
                customReportData.data[`${expandRecord.id}`].sort.sortType,
              sortOn: customReportData.data[`${expandRecord.id}`].sort.sortOn,
            },
            pageNumber: secondaryPageNumber,
          });
          if (this.props.reportDetailStatus === SUCCESS) {
            this.setState({
              secondarySelectedFilters: {
                ...this.state.secondarySelectedFilters,
                [changeSwitch]: {
                  ...this.state.secondarySelectedFilters[changeSwitch],
                  filterClasses:
                    customReportData.data[`${expandRecord.id}`].filters
                      .filterClasses,
                  filterState:
                    customReportData.data[`${expandRecord.id}`].filters
                      .filterState,
                  filterOffice:
                    customReportData.data[`${expandRecord.id}`].filters
                      .filterOffice,
                  filterCategory:
                    customReportData.data[`${expandRecord.id}`].filters
                      .filterCategory,
                  filterType:
                    customReportData.data[`${expandRecord.id}`].filters
                      .filterType,
                  filterStatus:
                    customReportData.data[`${expandRecord.id}`].filters
                      .filterStatus,
                  filterProprietor:
                    customReportData.data[`${expandRecord.id}`].filters
                      .filterProprietor,
                  date_of_application:
                    customReportData.data[`${expandRecord.id}`].filters
                      .date_of_application,
                  date_of_usage:
                    customReportData.data[`${expandRecord.id}`].filters
                      .date_of_usage,
                  date_of_usage_proposed:
                    customReportData.data[`${expandRecord.id}`].filters
                      .date_of_usage_proposed,
                  containsTerm:
                    customReportData.data[`${expandRecord.id}`].filters
                      .containsTerm,
                },
              },
              secondarySorting: {
                ...this.state.secondarySorting,
                [changeSwitch]: {
                  ...this.state.secondarySorting[changeSwitch],
                  sortType:
                    this.state.customReportData.data[`${expandRecord.id}`].sort
                      .sortType,
                  sortOn:
                    this.state.customReportData.data[`${expandRecord.id}`].sort
                      .sortOn,
                },
              },
              customReportData: {
                ...this.state.customReportData,
                [changeSwitch]: {
                  ...this.state.customReportData[changeSwitch],
                  data: {
                    ...this.state.customReportData[changeSwitch].data,
                    [expandRecord.id]: {
                      ...this.state.customReportData[changeSwitch].data[
                        `${expandRecord.id}`
                      ],
                      ...Report_defaultInitialReport,
                    },
                  },
                },
              },
            });
          }
        }
        this.setState({
          expandRecord: {
            [changeSwitch]: expandRecord.id,
          },
        });
      } else {
        notification["warning"]({
          message: "0 marks",
          description: "There are no marks against this report.",
        });
      }
    }
  }

  handleApplySecondaryFilters(searchInput, data) {
    const { changeSwitch } = this.state;
    const secondarySelectedFilters =
      this.state.secondarySelectedFilters[changeSwitch];
    const {
      filterClasses,
      filterJournalNumber,
      filterState,
      filterOffice,
      filterCategory,
      filterType,
      filterLogicType,
      filterStatus,
      filterProprietor,
      filterProprietorName,
      date_of_application,
      date_of_usage,
      date_of_usage_proposed,
    } = secondarySelectedFilters;
    const secondarySorting =
      this.props[`${changeSwitch}ReportDetailAppliedSorting`];

    if (
      filterClasses.length > 0 ||
      filterJournalNumber.length > 0 ||
      filterState.length > 0 ||
      filterOffice.length > 0 ||
      filterCategory.length > 0 ||
      filterType.length > 0 ||
      filterLogicType.length > 0 ||
      filterStatus.length > 0 ||
      filterProprietor.length > 0 ||
      filterProprietorName.length > 0 ||
      date_of_application.length > 0 ||
      date_of_usage.length > 0 ||
      date_of_usage_proposed ||
      searchInput !== secondarySelectedFilters?.containsTerm
    ) {
      this.props.getReportDetail({
        id: data.id,
        report_source:
          changeSwitch === "imageSearch"
            ? "IMAGE_SEARCH"
            : changeSwitch.toUpperCase(),
        filterFlag: true,
        filters: {
          ...secondarySelectedFilters,
          containsTerm: searchInput,
        },
        sortFlag: secondarySorting?.sortFlag,
        sorting: secondarySorting,
      });
      this.setState({
        secondarySelectedFilters: {
          ...this.state.secondarySelectedFilters,
          [changeSwitch]: {
            ...this.state.secondarySelectedFilters[changeSwitch],
            containsTerm: searchInput,
            filterFlag: true,
          },
        },
        customReportData: {
          ...this.state.customReportData,
          [changeSwitch]: {
            ...this.state.customReportData[changeSwitch],
            data: {
              ...this.state.customReportData[changeSwitch].data,
              [data.id]: {
                ...this.state.customReportData[changeSwitch].data[`${data.id}`],
                filter_flag: true,
                filters: {
                  ...secondarySelectedFilters,
                  filterFlag: true,
                  containsTerm: searchInput,
                },
                sort_flag: secondarySorting.sortFlag,
                sort: {
                  ...secondarySorting,
                },
              },
            },
          },
        },
      });
    } else {
      message.error("please select filter!");
    }
  }

  setDate1(val) {
    this.setState({ range1: val });
  }

  setDate2(val) {
    this.setState({ range2: val });
  }

  handleResetSecondaryFilters(data) {
    this.setState({ range1: null, range2: null });
    const { changeSwitch } = this.state;
    const secondarySorting =
      this.props[`${changeSwitch}ReportDetailAppliedSorting`];

    this.props.getReportDetail({
      id: data.id,
      report_source:
        changeSwitch === "imageSearch"
          ? "IMAGE_SEARCH"
          : changeSwitch.toUpperCase(),
      filterFlag: false,
      filters: Report_defaultSecondarySelectedFilters,
      sortFlag: secondarySorting.sortFlag,
      sorting: secondarySorting,
    });

    this.setState({
      secondarySelectedFilters: {
        ...this.state.secondarySelectedFilters,
        [changeSwitch]: Report_defaultSecondarySelectedFilters,
      },
      customReportData: {
        ...this.state.customReportData,
        [changeSwitch]: {
          ...this.state.customReportData[changeSwitch],
          data: {
            ...this.state.customReportData[changeSwitch].data,
            [data.id]: {
              ...this.state.customReportData[changeSwitch].data[`${data.id}`],
              filter_flag: false,
              filters: {
                ...Report_defaultSecondarySelectedFilters,
              },
              sort_flag: secondarySorting.sortFlag,
              sort: {
                ...secondarySorting,
              },
            },
          },
        },
      },
    });
  }

  handleApplySecondarySorting(sortValue, data) {
    const { changeSwitch } = this.state;
    const secondarySorting = this.state.secondarySorting[changeSwitch];
    const secondarySelectedFilters =
      this.props[`${changeSwitch}ReportDetailAppliedFilters`];
    const secondaryPageNumber =
      this.props[`${changeSwitch}ReportDetailPageNumber`];

    if (sortValue.length !== secondarySorting.sortValue) {
      if (sortValue[0] === "CLEAR") {
        this.props.getReportDetail({
          id: data.id,
          report_source:
            changeSwitch === "imageSearch"
              ? "IMAGE_SEARCH"
              : changeSwitch.toUpperCase(),
          filterFlag: secondarySelectedFilters
            ? secondarySelectedFilters?.filterFlag
            : false,
          filters: secondarySelectedFilters,
          sortFlag: false,
          sorting: Report_defaultSecondarySorting,
          pageNumber: secondaryPageNumber,
        });
        this.setState({
          secondarySorting: {
            ...this.state.secondarySorting,
            [changeSwitch]: Report_defaultSecondarySorting,
          },
          customReportData: {
            ...this.state.customReportData,
            [changeSwitch]: {
              ...this.state.customReportData[changeSwitch],
              data: {
                ...this.state.customReportData[changeSwitch].data,
                [data.id]: {
                  ...this.state.customReportData[changeSwitch].data[
                    `${data.id}`
                  ],
                  filter_flag: secondarySelectedFilters
                    ? secondarySelectedFilters?.filterFlag
                    : false,
                  filters: {
                    ...secondarySelectedFilters,
                  },
                  sort_flag: false,
                  sort: {
                    ...Report_defaultSecondarySorting,
                  },
                },
              },
            },
          },
        });
      } else {
        this.props.getReportDetail({
          id: data.id,
          report_source:
            changeSwitch === "imageSearch"
              ? "IMAGE_SEARCH"
              : changeSwitch.toUpperCase(),
          filterFlag: secondarySelectedFilters
            ? secondarySelectedFilters?.filterFlag
            : false,
          filters: secondarySelectedFilters,
          sortFlag: true,
          sorting: {
            ...secondarySorting,
            sortType: sortValue[0],
            sortOn: sortValue[1],
          },
          pageNumber: secondaryPageNumber,
        });
        this.setState({
          secondarySorting: {
            ...this.state.secondarySorting,
            [changeSwitch]: {
              ...this.state.secondarySorting[changeSwitch],
              sortType: sortValue[0],
              sortOn: sortValue[1],
              sortFlag: true,
              sortValue: sortValue,
            },
          },
          customReportData: {
            ...this.state.customReportData,
            [changeSwitch]: {
              ...this.state.customReportData[changeSwitch],
              data: {
                ...this.state.customReportData[changeSwitch].data,
                [data.id]: {
                  ...this.state.customReportData[changeSwitch].data[
                    `${data.id}`
                  ],
                  filter_flag: secondarySelectedFilters
                    ? secondarySelectedFilters?.filterFlag
                    : false,
                  filters: {
                    ...secondarySelectedFilters,
                  },
                  sort_flag: true,
                  sort: {
                    ...secondarySorting,
                    sortFlag: true,
                    sortType: sortValue[0],
                    sortOn: sortValue[1],
                  },
                },
              },
            },
          },
        });
      }
    } else {
      message.error("please select value!");
    }
  }

  async handleDeleteReportMark(expandRecord) {
    const { changeSwitch } = this.state;
    const customReportData = this.state.customReportData[changeSwitch];
    const secondarySelectedFilters =
      this.props[`${changeSwitch}ReportDetailAppliedFilters`];
    const secondarySorting =
      this.props[`${changeSwitch}ReportDetailAppliedSorting`];

    let filter_data = {};
    let sort_data = {};
    if (customReportData.data[`${expandRecord}`].filter_flag) {
      filter_data = {
        filter_class:
          customReportData.data[`${expandRecord}`].filters.filterClasses,
        filter_journal_number:
          customReportData.data[`${expandRecord}`].filters.filterJournalNumber,
        filter_state:
          customReportData.data[`${expandRecord}`].filters.filterState,
        filter_office:
          customReportData.data[`${expandRecord}`].filters.filterOffice,
        filter_trademark_category:
          customReportData.data[`${expandRecord}`].filters.filterCategory,
        filter_trademark_type:
          customReportData.data[`${expandRecord}`].filters.filterType,
        filter_logic_type:
          customReportData.data[`${expandRecord}`].filters.filterLogicType,
        filter_trademark_status:
          customReportData.data[`${expandRecord}`].filters.filterStatus,
        filter_proprietor:
          customReportData.data[`${expandRecord}`].filters.filterProprietor,
        filter_proprietor_name:
          customReportData.data[`${expandRecord}`].filters.filterProprietorName,
        filter_application_date:
          customReportData.data[`${expandRecord}`].filters.date_of_application,
        filter_date_of_usage:
          customReportData.data[`${expandRecord}`].filters.date_of_usage,
        filter_date_of_usage_proposed:
          customReportData.data[`${expandRecord}`].filters
            .date_of_usage_proposed,
        filter_contains_term:
          customReportData.data[`${expandRecord}`].filters.containsTerm,
      };
    } else {
      filter_data = {};
    }

    if (customReportData.data[`${expandRecord}`].sort_flag) {
      sort_data = {
        sort_type: customReportData.data[`${expandRecord}`].sort.sortType,
        sort_on: customReportData.data[`${expandRecord}`].sort.sortOn,
      };
    } else {
      sort_data = {};
    }
    await this.props.updateReportMark({
      id: expandRecord,
      report_source:
        changeSwitch === "imageSearch"
          ? "IMAGE_SEARCH"
          : changeSwitch.toUpperCase(),
      operation: "DELETE",
      report_type: "URGENT",
      report_file: false,
      select_all: customReportData.data[`${expandRecord}`].select_all,
      selected_mark_id_list:
        customReportData.data[`${expandRecord}`].selectedMark,
      sort_flag: customReportData.data[`${expandRecord}`].sort_flag,
      sort: sort_data,
      filter_flag: customReportData.data[`${expandRecord}`].filter_flag,
      filters: filter_data,
    });
    notification[this.props.updateReportMarkStatus.toLowerCase()]({
      message: "Mark Deleted",
      description: this.props.updateReportMarkMessage,
    });

    this.props.getReportDetail({
      id: expandRecord,
      report_source:
        changeSwitch === "imageSearch"
          ? "IMAGE_SEARCH"
          : changeSwitch.toUpperCase(),
      filterFlag: secondarySelectedFilters
        ? secondarySelectedFilters?.filterFlag
        : false,
      filters: secondarySelectedFilters,
      sortFlag: secondarySorting.sortFlag,
      sorting: secondarySorting,
    });
    this.setState({
      customReportData: {
        ...this.state.customReportData,
        [changeSwitch]: {
          ...this.state.customReportData[changeSwitch],
          ...Report_defaultCustomReportData,
          data: {
            ...this.state.customReportData[changeSwitch].data,
            [expandRecord]: {
              ...this.state.customReportData[changeSwitch].data[
                `${expandRecord}`
              ],
              markCount: 0,
              select_all: false,
              selectedMark: [],
              realSelectedMark: [],
              filteredMarkCount: 0,
            },
          },
        },
      },
      deleteMarkVisible: false,
    });
  }

  handleSecondarySelectedMark(eachMark, checked, expandRecord) {
    const { changeSwitch } = this.state;
    if (checked) {
      this.setState({
        customReportData: {
          ...this.state.customReportData,
          [changeSwitch]: {
            ...this.state.customReportData[changeSwitch],
            data: {
              ...this.state.customReportData[changeSwitch].data,
              [expandRecord]: {
                ...this.state.customReportData[changeSwitch].data[
                  `${expandRecord}`
                ],
                selectedMark: [
                  ...this.state.customReportData[changeSwitch].data[
                    `${expandRecord}`
                  ].selectedMark,
                  eachMark.id,
                ],
                realSelectedMark:
                  changeSwitch === "ipIndia"
                    ? [
                        ...this.state.customReportData[changeSwitch].data[
                          `${expandRecord}`
                        ].realSelectedMark,
                        eachMark.real_trademark_id,
                      ]
                    : [],
                markCount:
                  this.state.customReportData[changeSwitch].data[
                    `${expandRecord}`
                  ].markCount + 1,
              },
            },
            markCount: this.state.customReportData[changeSwitch].markCount + 1,
          },
        },
      });
    } else {
      this.setState({
        customReportData: {
          ...this.state.customReportData,
          [changeSwitch]: {
            ...this.state.customReportData[changeSwitch],
            data: {
              ...this.state.customReportData[changeSwitch].data,
              [expandRecord]: {
                ...this.state.customReportData[changeSwitch].data[
                  `${expandRecord}`
                ],
                selectedMark: this.state.customReportData[changeSwitch].data[
                  `${expandRecord}`
                ].selectedMark.filter((items) => items !== eachMark.id),
                realSelectedMark:
                  changeSwitch === "ipIndia"
                    ? this.state.customReportData[changeSwitch].data[
                        `${expandRecord}`
                      ].realSelectedMark.filter(
                        (items) => items !== eachMark.real_trademark_id,
                      )
                    : [],
                markCount:
                  this.state.customReportData[changeSwitch].data[
                    `${expandRecord}`
                  ].markCount - 1,
              },
            },
            markCount: this.state.customReportData[changeSwitch].markCount - 1,
          },
        },
      });
    }
  }

  handlePrimaryPageChange(pageNumber) {
    const { changeSwitch } = this.state;
    const primarySelectedFilters =
      this.props[`${changeSwitch}ReportAppliedFilters`];
    const primarySorting = this.props[`${changeSwitch}ReportAppliedSorting`];

    this.props.getReport({
      report_source:
        changeSwitch === "imageSearch"
          ? "IMAGE_SEARCH"
          : changeSwitch.toUpperCase(),
      filterFlag: primarySelectedFilters
        ? primarySelectedFilters.filterFlag
        : false,
      filters: primarySelectedFilters,
      sortFlag: primarySorting.sortFlag,
      sorting: primarySorting,
      check: false,
      files: false,
      pageNumber: pageNumber,
    });
    this.setState({
      primaryPageNumber: {
        ...this.state.primaryPageNumber,
        [changeSwitch]: pageNumber,
      },
    });
  }

  handleSecondaryPageChange(pageNumber, data) {
    const { changeSwitch } = this.state;
    const secondarySelectedFilters =
      this.props[`${changeSwitch}ReportDetailAppliedFilters`];
    const secondarySorting =
      this.props[`${changeSwitch}ReportDetailAppliedSorting`];

    this.props.getReportDetail({
      id: data.id,
      report_source:
        changeSwitch === "imageSearch"
          ? "IMAGE_SEARCH"
          : changeSwitch.toUpperCase(),
      filterFlag: secondarySelectedFilters
        ? secondarySelectedFilters?.filterFlag
        : false,
      filters: secondarySelectedFilters,
      sortFlag: secondarySorting.sortFlag,
      sorting: secondarySorting,
      pageNumber: pageNumber,
    });
    this.setState({
      secondaryPageNumber: {
        ...this.state.secondaryPageNumber,
        [changeSwitch]: pageNumber,
      },
    });
  }

  onCloseDrawer(markId) {
    const { changeSwitch } = this.state;
    this.setState({
      realTimeReportvisible: false,
      drawersVisibility: {
        ...this.state.drawersVisibility,
        createReport: false,
      },
      customReportData: {
        ...this.state.customReportData,
        [changeSwitch]: {
          ...this.state.customReportData[changeSwitch],
          ...Report_defaultCustomReportData,
          data: {
            ...this.state.customReportData[changeSwitch].data,
            [markId]: {
              ...this.state.customReportData[changeSwitch].data[`${markId}`],
              markCount: 0,
              select_all: false,
              selectedMark: [],
              realSelectedMark: [],
              filteredMarkCount: 0,
            },
          },
          selectAllCount:
            this.state.customReportData[changeSwitch].selectAllCount - 1,
          selectedMark: this.state.customReportData[
            changeSwitch
          ].selectedMark.filter((item) => item !== markId),
        },
      },
    });
  }

  render() {
    const { changeSwitch } = this.state;
    const { userSubscriptions } = this.props;

    const report = this.props[`${changeSwitch}Report`];
    const reportStatus = this.props[`${changeSwitch}ReportStatus`];
    const reportDetail = this.props[`${changeSwitch}ReportDetail`];
    const reportDetailStatus = this.props[`${changeSwitch}ReportDetailStatus`];

    const customReportData = this.state.customReportData[changeSwitch];
    const markId = this.state.markId[changeSwitch];
    const expandRecord = this.state.expandRecord[changeSwitch];
    const selectedView = this.state.selectedView[changeSwitch];

    const primarySorting = this.state.primarySorting[changeSwitch];
    const primaryPageNumber = this.state.primaryPageNumber[changeSwitch];

    const secondaryFiltersVisibility =
      this.state.secondaryFiltersVisibility[changeSwitch];
    const secondarySelectedFilters =
      this.state.secondarySelectedFilters[changeSwitch];
    const secondaryFiltersSearchTerms =
      this.state.secondaryFiltersSearchTerms[changeSwitch];
    const secondarySorting = this.state.secondarySorting[changeSwitch];
    const secondaryPageNumber = this.state.secondaryPageNumber[changeSwitch];

    const filterMenuScroll = {
      maxHeight: "175px",
      minWidth: "150px",
      overflowY: "scroll",
    };

    let noFilterData = (
      <Menu>
        <Menu.Item>No Filters Data Found</Menu.Item>
      </Menu>
    );
    let classesMenu = noFilterData;
    let journalNumberMenu = noFilterData;
    let stateMenu = noFilterData;
    let officeMenu = noFilterData;
    let categoryMenu = noFilterData;
    let typeMenu = noFilterData;
    let logicTypeMenu = noFilterData;
    let statusMenu = noFilterData;
    let proprietorMenu = noFilterData;
    let proprietorNameMenu = noFilterData;

    const switchOptions = [];
    if (userSubscriptions.search?.includes("MARK")) {
      switchOptions.push("mark");
      // switchOptions.push("ipIndia");
    }
    if (userSubscriptions.search.includes("PROPRIETOR")) {
      switchOptions.push("proprietor");
    }
    if (userSubscriptions.search.includes("IMAGE_SEARCH")) {
      switchOptions.push("imageSearch");
    }
    const switchesMenu = (
      <Menu
        selectable={true}
        selectedKeys={[changeSwitch]}
        onClick={({ key }) => this.setState({ changeSwitch: key })}
        style={{
          maxHeight: "200px",
          width: "200px",
          fontSize: "16px",
          overflow: "auto",
          boxShadow: "0px 0px 5px #c9c9c9",
        }}
      >
        {switchOptions.map((e) => (
          <Menu.Item key={e} style={{ fontSize: "16px" }}>
            {e === "mark" && <div>Trademark Reports</div>}
            {e === "proprietor" && <div>Proprietor Reports</div>}
            {/* {e === "ipIndia" && <div>IP India Reports</div>} */}
            {e === "imageSearch" && <div>Image Search Reports</div>}
          </Menu.Item>
        ))}
      </Menu>
    );

    if (reportDetailStatus === SUCCESS) {
      let filters = this.props[`${changeSwitch}ReportDetail`].filters;
      let classes = [...new Set(filters.class)].sort(function (a, b) {
        return a - b;
      });
      let journalNumber = [...new Set(filters.journal_number)].sort(
        function (a, b) {
          return a - b;
        },
      );
      let state = [...new Set(filters.state)].sort(function (a, b) {
        return a - b;
      });
      let office = [...new Set(filters.office)].sort(function (a, b) {
        return a - b;
      });
      let category = [...new Set(filters.category)].sort(function (a, b) {
        return a - b;
      });
      let type = [...new Set(filters.type)].sort(function (a, b) {
        return a - b;
      });
      let logicType = filters.logic_type;
      let status = [...new Set(filters.status)].sort(function (a, b) {
        return a - b;
      });
      let proprietor = [...new Set(filters.proprietor)].sort(function (a, b) {
        return a - b;
      });
      let proprietorName = filters.proprietor_name;

      if (classes !== null && classes.length > 0) {
        let filteredClasses = classes.filter((eachClasses) =>
          checkValue(`${eachClasses}`)
            ? `${eachClasses}`?.includes(
                `${secondaryFiltersSearchTerms.classes}`,
              )
            : "",
        );
        classesMenu = (
          <>
            <Search
              placeholder="Search Class"
              onChange={(event) =>
                this.changeStateValue_L2(
                  "secondaryFiltersSearchTerms",
                  changeSwitch,
                  "classes",
                  event.target.value,
                )
              }
              value={secondaryFiltersSearchTerms.classes}
              allowClear
            />
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredClasses.length}
              itemSize={35}
              itemData={filteredClasses}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={secondarySelectedFilters?.filterClasses}
                    onSelect={(event) =>
                      this.changeStateValue_L2(
                        "secondarySelectedFilters",
                        changeSwitch,
                        "filterClasses",
                        event.selectedKeys,
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L2(
                        "secondarySelectedFilters",
                        changeSwitch,
                        "filterClasses",
                        event.selectedKeys,
                      )
                    }
                    className="disable-box-and-padding"
                  >
                    <Menu.Item
                      key={data[index]}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Text
                        className="text-camelCase"
                        style={{ fontWeight: "400" }}
                      >
                        {data[index]}
                      </Text>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }

      if (journalNumber !== null && journalNumber.length > 0) {
        let filteredJournalNumber = journalNumber.filter((e) =>
          checkValue(`${e}`)
            ? `${e}`?.includes(`${secondaryFiltersSearchTerms.journalNumber}`)
            : "",
        );
        journalNumberMenu = (
          <>
            <Search
              placeholder="Search Journal Number"
              onChange={(event) =>
                this.changeStateValue_L2(
                  "secondaryFiltersSearchTerms",
                  changeSwitch,
                  "journalNumber",
                  event.target.value,
                )
              }
              value={secondaryFiltersSearchTerms.journalNumber}
              allowClear
            />
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredJournalNumber.length}
              itemSize={35}
              itemData={filteredJournalNumber}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={secondarySelectedFilters?.filterJournalNumber}
                    onSelect={(event) =>
                      this.changeStateValue_L2(
                        "secondarySelectedFilters",
                        changeSwitch,
                        "filterJournalNumber",
                        event.selectedKeys,
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L2(
                        "secondarySelectedFilters",
                        changeSwitch,
                        "filterJournalNumber",
                        event.selectedKeys,
                      )
                    }
                    className="disable-box-and-padding"
                  >
                    <Menu.Item
                      key={data[index]}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Text
                        className="text-camelCase"
                        style={{ fontWeight: "400" }}
                      >
                        {data[index]}
                      </Text>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }

      if (state !== null && state.length > 0) {
        let filteredState = state.filter((eachState) =>
          checkValue(eachState)
            ? eachState
                .toLowerCase()
                ?.includes(secondaryFiltersSearchTerms.state)
            : "",
        );
        stateMenu = (
          <>
            <Search
              placeholder="Search State"
              onChange={(event) =>
                this.changeStateValue_L2(
                  "secondaryFiltersSearchTerms",
                  changeSwitch,
                  "state",
                  event.target.value.toLowerCase(),
                )
              }
              value={secondaryFiltersSearchTerms.state}
              allowClear
            />
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredState.length}
              itemSize={35}
              itemData={filteredState}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={secondarySelectedFilters?.filterState}
                    onSelect={(event) =>
                      this.changeStateValue_L2(
                        "secondarySelectedFilters",
                        changeSwitch,
                        "filterState",
                        event.selectedKeys,
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L2(
                        "secondarySelectedFilters",
                        changeSwitch,
                        "filterState",
                        event.selectedKeys,
                      )
                    }
                    className="disable-box-and-padding"
                  >
                    <Menu.Item
                      key={data[index]}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Text
                        className="text-camelCase"
                        style={{ fontWeight: "400" }}
                      >
                        {data[index].toLowerCase()}
                      </Text>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }

      if (office !== null && office.length > 0) {
        let filteredOffice = office.filter((eachOffice) =>
          checkValue(eachOffice)
            ? eachOffice.name
                .toLowerCase()
                ?.includes(secondaryFiltersSearchTerms.office)
            : "",
        );
        officeMenu = (
          <>
            <Search
              placeholder="Search Office"
              onChange={(event) =>
                this.changeStateValue_L2(
                  "secondaryFiltersSearchTerms",
                  changeSwitch,
                  "office",
                  event.target.value.toLowerCase(),
                )
              }
              value={secondaryFiltersSearchTerms.office}
              allowClear
            />
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredOffice.length}
              itemSize={35}
              itemData={filteredOffice}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={secondarySelectedFilters?.filterOffice}
                    onSelect={(event) =>
                      this.changeStateValue_L2(
                        "secondarySelectedFilters",
                        changeSwitch,
                        "filterOffice",
                        event.selectedKeys,
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L2(
                        "secondarySelectedFilters",
                        changeSwitch,
                        "filterOffice",
                        event.selectedKeys,
                      )
                    }
                    className="disable-box-and-padding"
                  >
                    <Menu.Item
                      key={data[index].id}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Text
                        className="text-camelCase"
                        style={{ fontWeight: "400" }}
                      >
                        {data[index].name.toLowerCase()}
                      </Text>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }

      if (category !== null && category.length > 0) {
        let filteredCategory = category.filter((eachCategory) =>
          checkValue(eachCategory)
            ? eachCategory.name
                .toLowerCase()
                ?.includes(secondaryFiltersSearchTerms.category)
            : "",
        );
        categoryMenu = (
          <>
            <Search
              placeholder="Search Category"
              onChange={(event) =>
                this.changeStateValue_L2(
                  "secondaryFiltersSearchTerms",
                  changeSwitch,
                  "category",
                  event.target.value.toLowerCase(),
                )
              }
              value={secondaryFiltersSearchTerms.category}
              allowClear
            />
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredCategory.length}
              itemSize={35}
              itemData={filteredCategory}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={secondarySelectedFilters?.filterCategory}
                    onSelect={(event) =>
                      this.changeStateValue_L2(
                        "secondarySelectedFilters",
                        changeSwitch,
                        "filterCategory",
                        event.selectedKeys,
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L2(
                        "secondarySelectedFilters",
                        changeSwitch,
                        "filterCategory",
                        event.selectedKeys,
                      )
                    }
                    className="disable-box-and-padding"
                  >
                    <Menu.Item
                      key={data[index].id}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Text
                        className="text-camelCase"
                        style={{ fontWeight: "400" }}
                      >
                        {data[index].name.toLowerCase()}
                      </Text>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }

      if (type !== null && type.length > 0) {
        let filteredType = type.filter((eachType) =>
          checkValue(eachType)
            ? eachType.name
                .toLowerCase()
                ?.includes(secondaryFiltersSearchTerms.type)
            : "",
        );
        typeMenu = (
          <>
            <Search
              placeholder="Search Type"
              onChange={(event) =>
                this.changeStateValue_L2(
                  "secondaryFiltersSearchTerms",
                  changeSwitch,
                  "type",
                  event.target.value.toLowerCase(),
                )
              }
              value={secondaryFiltersSearchTerms.type}
              allowClear
            />
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredType.length}
              itemSize={35}
              itemData={filteredType}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={secondarySelectedFilters?.filterType}
                    onSelect={(event) =>
                      this.changeStateValue_L2(
                        "secondarySelectedFilters",
                        changeSwitch,
                        "filterType",
                        event.selectedKeys,
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L2(
                        "secondarySelectedFilters",
                        changeSwitch,
                        "filterType",
                        event.selectedKeys,
                      )
                    }
                    className="disable-box-and-padding"
                  >
                    <Menu.Item
                      key={data[index].id}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Text
                        className="text-camelCase"
                        style={{ fontWeight: "400" }}
                      >
                        {data[index].name.toLowerCase()}
                      </Text>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }

      if (logicType !== null && logicType.length > 0) {
        let filteredLogicType = logicType.filter((eachType) =>
          checkValue(eachType)
            ? eachType
                .toLowerCase()
                ?.includes(secondaryFiltersSearchTerms.logicType)
            : "",
        );
        logicTypeMenu = (
          <>
            <Search
              placeholder="Search Logic Type"
              onChange={(event) =>
                this.changeStateValue_L2(
                  "secondaryFiltersSearchTerms",
                  changeSwitch,
                  "logicType",
                  event.target.value.toLowerCase(),
                )
              }
              value={secondaryFiltersSearchTerms.logicType}
              allowClear
            />
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredLogicType.length}
              itemSize={35}
              itemData={filteredLogicType}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={secondarySelectedFilters?.filterLogicType}
                    onSelect={(event) =>
                      this.changeStateValue_L2(
                        "secondarySelectedFilters",
                        changeSwitch,
                        "filterLogicType",
                        event.selectedKeys,
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L2(
                        "secondarySelectedFilters",
                        changeSwitch,
                        "filterLogicType",
                        event.selectedKeys,
                      )
                    }
                    className="disable-box-and-padding"
                  >
                    <Menu.Item
                      key={data[index]}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Text
                        className="text-camelCase"
                        style={{ fontWeight: "400" }}
                      >
                        {data[index].toLowerCase() === "word"
                          ? "WORD (CONTAINS)"
                          : data[index]}
                      </Text>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }

      if (status !== null && status.length > 0) {
        let filteredStatus = status.filter((eachStatus) =>
          checkValue(eachStatus)
            ? eachStatus
                .toLowerCase()
                ?.includes(secondaryFiltersSearchTerms.status)
            : "",
        );
        statusMenu = (
          <>
            <Search
              placeholder="Search Status"
              onChange={(event) =>
                this.changeStateValue_L2(
                  "secondaryFiltersSearchTerms",
                  changeSwitch,
                  "status",
                  event.target.value.toLowerCase(),
                )
              }
              value={secondaryFiltersSearchTerms.status}
              allowClear
            />
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredStatus.length}
              itemSize={35}
              itemData={filteredStatus}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={secondarySelectedFilters?.filterStatus}
                    onSelect={(event) =>
                      this.changeStateValue_L2(
                        "secondarySelectedFilters",
                        changeSwitch,
                        "filterStatus",
                        event.selectedKeys,
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L2(
                        "secondarySelectedFilters",
                        changeSwitch,
                        "filterStatus",
                        event.selectedKeys,
                      )
                    }
                    className="disable-box-and-padding"
                  >
                    <Menu.Item
                      key={data[index]}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Text
                        className="text-camelCase"
                        style={{ fontWeight: "400" }}
                      >
                        {data[index].toLowerCase()}
                      </Text>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }

      if (proprietor !== null && proprietor.length > 0) {
        let filteredProprietor = proprietor.filter((eachProprietor) =>
          checkValue(eachProprietor)
            ? eachProprietor.name
                .toLowerCase()
                ?.includes(secondaryFiltersSearchTerms.proprietor)
            : "",
        );
        proprietorMenu = (
          <>
            <Search
              placeholder="Search Proprietor"
              onChange={(event) =>
                this.changeStateValue_L2(
                  "secondaryFiltersSearchTerms",
                  changeSwitch,
                  "proprietor",
                  event.target.value.toLowerCase(),
                )
              }
              value={secondaryFiltersSearchTerms.proprietor}
              allowClear
            />
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredProprietor.length}
              itemSize={35}
              itemData={filteredProprietor}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={secondarySelectedFilters?.filterProprietor}
                    onSelect={(event) =>
                      this.changeStateValue_L2(
                        "secondarySelectedFilters",
                        changeSwitch,
                        "filterProprietor",
                        event.selectedKeys,
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L2(
                        "secondarySelectedFilters",
                        changeSwitch,
                        "filterProprietor",
                        event.selectedKeys,
                      )
                    }
                    className="disable-box-and-padding"
                  >
                    <Menu.Item
                      key={data[index].id}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Text
                        className="text-camelCase"
                        style={{ fontWeight: "400" }}
                      >
                        {data[index].name.toLowerCase()}
                      </Text>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }

      if (proprietorName !== null && proprietorName.length > 0) {
        let filteredProprietorName = proprietorName.filter((eachProprietor) =>
          checkValue(eachProprietor)
            ? eachProprietor
                .toLowerCase()
                ?.includes(secondaryFiltersSearchTerms.proprietorName)
            : "",
        );
        proprietorNameMenu = (
          <>
            <Search
              placeholder="Search Proprietor"
              onChange={(event) =>
                this.changeStateValue_L2(
                  "secondaryFiltersSearchTerms",
                  changeSwitch,
                  "proprietorName",
                  event.target.value.toLowerCase(),
                )
              }
              value={secondaryFiltersSearchTerms.proprietorName}
              allowClear
            />
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredProprietorName.length}
              itemSize={35}
              itemData={filteredProprietorName}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={
                      secondarySelectedFilters?.filterProprietorName
                    }
                    onSelect={(event) =>
                      this.changeStateValue_L2(
                        "secondarySelectedFilters",
                        changeSwitch,
                        "filterProprietorName",
                        event.selectedKeys,
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L2(
                        "secondarySelectedFilters",
                        changeSwitch,
                        "filterProprietorName",
                        event.selectedKeys,
                      )
                    }
                    className="disable-box-and-padding"
                  >
                    <Menu.Item
                      key={data[index]}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Text
                        className="text-camelCase"
                        style={{ fontWeight: "400" }}
                      >
                        {data[index].toLowerCase()}
                      </Text>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }
    }

    const moreActionsMenu = (
      <Menu
        selectable={true}
        multiple={true}
        selectedKeys={[]}
        onSelect={(event) =>
          this.changeStateValue_L2(
            "secondarySelectedFilters",
            changeSwitch,
            "page",
            event.selectedKeys,
          )
        }
        onDeselect={(event) =>
          this.changeStateValue_L2(
            "secondarySelectedFilters",
            changeSwitch,
            "page",
            event.selectedKeys,
          )
        }
        style={filterMenuScroll}
      >
        <Menu.Item
          key="Select page"
          onClick={() =>
            reportDetail?.marks.length &&
            this.setState({
              customReportData: {
                ...this.state.customReportData,
                [changeSwitch]: {
                  ...this.state.customReportData[changeSwitch],
                  data: {
                    ...this.state.customReportData[changeSwitch].data,
                    [expandRecord]: {
                      ...this.state.customReportData[changeSwitch].data[
                        `${expandRecord}`
                      ],
                      selectedMark: [
                        ...new Set([
                          ...reportDetail?.marks.map((eachMark) => eachMark.id),
                          ...this.state.customReportData[changeSwitch].data[
                            `${expandRecord}`
                          ].selectedMark,
                        ]),
                      ],
                      realSelectedMark:
                        changeSwitch === "ipIndia"
                          ? [
                              ...new Set([
                                ...reportDetail?.marks.map(
                                  (eachMark) => eachMark.real_trademark_id,
                                ),
                                ...this.state.customReportData[changeSwitch]
                                  .data[`${expandRecord}`].realSelectedMark,
                              ]),
                            ]
                          : [],
                      markCount: [
                        ...new Set([
                          ...reportDetail?.marks.map((eachMark) => eachMark.id),
                          ...this.state.customReportData[changeSwitch].data[
                            `${expandRecord}`
                          ].selectedMark,
                        ]),
                      ].length,
                    },
                  },
                },
              },
            })
          }
        >
          Select page
        </Menu.Item>
        <Menu.Item
          key="Deselect page"
          onClick={() =>
            this.setState({
              customReportData: {
                ...this.state.customReportData,
                [changeSwitch]: {
                  ...this.state.customReportData[changeSwitch],
                  data: {
                    ...this.state.customReportData[changeSwitch].data,
                    [expandRecord]: {
                      ...this.state.customReportData[changeSwitch].data[
                        `${expandRecord}`
                      ],
                      selectedMark: this.state.customReportData[
                        changeSwitch
                      ].data[`${expandRecord}`].selectedMark.filter(
                        (data) =>
                          !reportDetail?.marks
                            .map((eachMark) => eachMark.id)
                            ?.includes(data),
                      ),
                      realSelectedMark:
                        changeSwitch === "ipIndia"
                          ? this.state.customReportData[changeSwitch].data[
                              `${expandRecord}`
                            ].realSelectedMark.filter(
                              (data) =>
                                !reportDetail?.marks
                                  .map((eachMark) => eachMark.real_trademark_id)
                                  ?.includes(data),
                            )
                          : [],
                      markCount: this.state.customReportData[changeSwitch].data[
                        `${expandRecord}`
                      ].selectedMark.filter(
                        (data) =>
                          !reportDetail?.marks
                            .map((eachMark) => eachMark.id)
                            ?.includes(data),
                      ).length,
                    },
                  },
                },
              },
            })
          }
        >
          Deselect page
        </Menu.Item>
      </Menu>
    );

    return (
      <div>
        {/* reports-header */}
        <header className="General-Header m-top-10">
          <Row>
            <Col span={16} className="alignL">
              {changeSwitch !== "imageSearch" && (
                <>
                  <Search
                    placeholder="Search mark name"
                    onSearch={(searchInput) =>
                      this.handleApplyPrimaryFilters(searchInput)
                    }
                    enterButton={
                      <Button
                        style={{
                          backgroundColor: "#4285f4",
                          color: "#ffffff",
                          fontWeight: "600",
                        }}
                      >
                        Apply
                      </Button>
                    }
                    style={{ width: "20vw", minWidth: "150px" }}
                  />
                  <Button
                    type="link"
                    style={{ padding: "0px 10px" }}
                    onClick={() => this.handleResetPrimaryFilters()}
                  >
                    Reset
                  </Button>
                  <Cascader
                    data-step="1"
                    data-intro="Sort reports in different orders."
                    data-highlightclass="Data-Highlight-Class-Transparent"
                    data-tooltipclass="Data-Tooltip-Class-General"
                    options={[
                      {
                        value: "ASC",
                        label: "Ascending",
                        children: [
                          {
                            value: "TITLE",
                            label: "Title",
                          },
                          {
                            value: "MODIFIED",
                            label: "Modified Date",
                          },
                        ],
                      },
                      {
                        value: "DESC",
                        label: "Descending",
                        children: [
                          {
                            value: "TITLE",
                            label: "Title",
                          },
                          {
                            value: "MODIFIED",
                            label: "Modified Date",
                          },
                        ],
                      },
                      {
                        value: "CLEAR",
                        label: "Clear All",
                        children: [],
                      },
                    ]}
                    allowClear={false}
                    placeholder="Sorting"
                    expandTrigger="hover"
                    value={primarySorting?.sortValue}
                    onChange={(value) => this.handleApplyPrimarySorting(value)}
                  />
                </>
              )}
              {customReportData?.selectAllCount > 0 && (
                <>
                  <span style={{ padding: "0px 5px", display: "inline-block" }}>
                    <Popover
                      trigger="click"
                      visible={this.state.visible}
                      onVisibleChange={(visible) =>
                        this.changeStateValue_L0("visible", visible)
                      }
                      content={
                        <div>
                          <Text>Do you want to delete selected report</Text>
                          <div className="m-top-10 alignC">
                            <Button
                              style={{ margin: "0px 5px" }}
                              onClick={() =>
                                this.changeStateValue_L0("visible", false)
                              }
                            >
                              Cancel
                            </Button>
                            <Button
                              danger
                              style={{ margin: "0px 5px" }}
                              loading={
                                this.props.deleteReportStatus === LOADING
                              }
                              onClick={() => this.handleDeleteReport()}
                            >
                              Confirm
                            </Button>
                          </div>
                        </div>
                      }
                    >
                      <Badge
                        overflowCount={9999}
                        count={customReportData.selectAllCount}
                        style={{ marginTop: "3px" }}
                      >
                        <Button ghost danger>
                          Delete Report
                        </Button>
                      </Badge>
                    </Popover>
                  </span>
                  <MinusCircleOutlined
                    style={{ marginLeft: "10px", fontSize: "14px" }}
                    onClick={() => this.handleClearSelectedReport(expandRecord)}
                  />
                </>
              )}
            </Col>
            <Col span={8} className="alignR">
              {report && (
                <span style={{ padding: "0px 10px" }}>
                  <Text strong>Quota:- </Text>
                  <Text>
                    <Tooltip title="Left Quota">{report.left_quota}</Tooltip>/
                    <Tooltip title="Total Quota">{report.quota}</Tooltip>
                  </Text>
                </span>
              )}
              {
                <Dropdown overlay={switchesMenu}>
                  <Button
                    style={{
                      color: "#ffffff",
                      fontWeight: "600",
                      border: "none",
                      backgroundColor: "#4285f4",
                      padding: "0px 5px",
                    }}
                  >
                    {changeSwitch === "mark"
                      ? "Trademark Reports"
                      : changeSwitch === "proprietor"
                        ? "Proprietor Reports"
                        : changeSwitch === "ipIndia"
                          ? "IP India Reports"
                          : changeSwitch === "imageSearch"
                            ? "Image Search Reports"
                            : ""}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              }
            </Col>
          </Row>
        </header>
        {/* reports-header */}
        {/* reports-content */}
        <div
          className="General-Content vertical_scroll"
          style={{
            height: `${
              this.props.searchAlert.length > 0
                ? "calc(100vh - 135px)"
                : "calc(100vh - 95px)"
            }`,
          }}
        >
          <Row>
            <Col span={24}>
              {reportStatus === LOADING && (
                <div
                  className="spin-container"
                  style={{ height: "calc(100vh - 100px)" }}
                >
                  <Spin size="large" />
                </div>
              )}
              {reportStatus === SUCCESS && (
                <>
                  {report &&
                    report.reports.map((data) => (
                      <React.Fragment key={`${data.id}`}>
                        <div id={data.id} className="Record-Row">
                          <div
                            className="Record-Row-Cell"
                            style={{ width: "20px" }}
                          >
                            <Checkbox
                              disabled={!data.available}
                              checked={
                                customReportData?.data[`${data.id}`]
                                  ? customReportData?.data[`${data.id}`]
                                      .select_all
                                  : false
                              }
                              onClick={(event) =>
                                this.handlePrimarySelectedMark(
                                  event,
                                  data.id,
                                  data.marks_count,
                                )
                              }
                            />
                          </div>
                          <div className="Record-Row-Cell">
                            {changeSwitch !== "imageSearch" ? (
                              <Tooltip title={`Mark Name- ${data.title}`}>
                                <Text>{data.title}</Text>
                              </Tooltip>
                            ) : (
                              <Image src={data.image} width={"96px"} />
                            )}
                          </div>
                          <div className="Record-Row-Cell">
                            <Text>{data.marks_count} marks in report</Text>
                          </div>
                          <div className="Record-Row-Cell">
                            <Tooltip title={`Modified Date- ${data.modified}`}>
                              <Text>{data.modified}</Text>
                            </Tooltip>
                          </div>
                          <div className="Record-Row-Cell">
                            <Popover
                              trigger="click"
                              visible={
                                data.available &&
                                this.state.realTimeReportvisible &&
                                markId === data.id
                              }
                              onVisibleChange={(visible) =>
                                this.onVisibleRealTimePopover(visible, data.id)
                              }
                              content={
                                <div>
                                  <Checkbox
                                    onClick={(event) =>
                                      this.changeStateValue_L0(
                                        "reportFile",
                                        event.target.checked,
                                      )
                                    }
                                    checked={this.state.reportFile}
                                  >
                                    Do you want the processed report
                                  </Checkbox>
                                  <div className="m-top-10 alignC">
                                    <Button
                                      style={{ margin: "0px 5px" }}
                                      onClick={() =>
                                        this.changeStateValue_L0(
                                          "realTimeReportvisible",
                                          false,
                                        )
                                      }
                                    >
                                      Cancel
                                    </Button>
                                    {this.state.reportFile ? (
                                      <Button
                                        danger
                                        style={{ margin: "0px 5px" }}
                                        onClick={() =>
                                          this.setState({
                                            realTimeReportvisible: false,
                                            drawersVisibility: {
                                              ...this.state.drawersVisibility,
                                              createReport: true,
                                            },
                                            expandRecord: {
                                              [changeSwitch]: false,
                                            },
                                            customReportData: {
                                              ...this.state.customReportData,
                                              [changeSwitch]: {
                                                ...this.state.customReportData[
                                                  changeSwitch
                                                ],
                                                data: {
                                                  ...this.state
                                                    .customReportData[
                                                    changeSwitch
                                                  ].data,
                                                  [data.id]: {
                                                    ...this.state
                                                      .customReportData[
                                                      changeSwitch
                                                    ].data[`${data.id}`],
                                                  },
                                                },
                                                id: data.id,
                                                report_source:
                                                  changeSwitch === "imageSearch"
                                                    ? "IMAGE_SEARCH"
                                                    : changeSwitch.toUpperCase(),
                                                operation: "REALTIME",
                                                report_file:
                                                  this.state.reportFile,
                                                report_file_name: this.state
                                                  .reportFile
                                                  ? ""
                                                  : null,
                                                report_file_types: this.state
                                                  .reportFile
                                                  ? ["PDF"]
                                                  : null,
                                              },
                                            },
                                          })
                                        }
                                      >
                                        Confirm
                                      </Button>
                                    ) : (
                                      <Button
                                        danger
                                        style={{ margin: "0px 5px" }}
                                        loading={
                                          this.props
                                            .createSearchReportStatus ===
                                          LOADING
                                        }
                                        onClick={() =>
                                          this.handleRealTimeReport(data.id)
                                        }
                                      >
                                        Confirm
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              }
                            >
                              <Button disabled={!data.available} type="link">
                                {data.available
                                  ? changeSwitch === "ipIndia"
                                    ? ""
                                    : "Get real time report"
                                  : "Processing..."}
                              </Button>
                            </Popover>
                          </div>
                          <div className="Record-Row-Cell">
                            <Button
                              data-step="2"
                              data-intro="Expand your report from here and create sub reports from it."
                              data-highlightclass="Data-Highlight-Class-General"
                              data-tooltipclass="Data-Tooltip-Class-General"
                              disabled={!data.available}
                              href={`#${data.id}`}
                              type="link"
                              onClick={() =>
                                this.handleExpandRecord(
                                  expandRecord === data.id ? false : data,
                                )
                              }
                            >
                              See Marks <DownOutlined />
                            </Button>
                          </div>
                          <div
                            className="Record-Row-Cell"
                            style={{ width: "30px" }}
                          >
                            {customReportData?.data[`${data.id}`] &&
                              customReportData?.data[`${data.id}`]
                                .select_all && (
                                <Tooltip title={"Create Report"}>
                                  <FileAddFilled
                                    className={
                                      expandRecord === data.id
                                        ? "display_none"
                                        : ""
                                    }
                                    style={{ fontSize: "20px" }}
                                    onClick={() =>
                                      this.setState({
                                        drawersVisibility: {
                                          ...this.state.drawersVisibility,
                                          createReport: true,
                                        },
                                        markId: {
                                          ...this.state.markId,
                                          [changeSwitch]: data.id,
                                        },
                                        customReportData: {
                                          ...this.state.customReportData,
                                          [changeSwitch]: {
                                            ...this.state.customReportData[
                                              changeSwitch
                                            ],
                                            data: {
                                              ...this.state.customReportData[
                                                changeSwitch
                                              ].data,
                                              [data.id]: {
                                                ...this.state.customReportData[
                                                  changeSwitch
                                                ].data[`${data.id}`],
                                              },
                                            },
                                            id: data.id,
                                            report_source:
                                              changeSwitch === "imageSearch"
                                                ? "IMAGE_SEARCH"
                                                : changeSwitch.toUpperCase(),
                                            operation: "DOWNLOAD",
                                            report_file: true,
                                            report_file_name: "",
                                            report_file_types: ["PDF"],
                                          },
                                        },
                                      })
                                    }
                                  />
                                </Tooltip>
                              )}
                          </div>
                        </div>

                        {/* report-details */}
                        {expandRecord === data.id && (
                          <div
                            style={{
                              height: "calc(100vh- 70px)",
                              overflow: "auto",
                              margin: "0px 5px",
                              borderRadius: "5px",
                              border: "1px solid #c9c9c9",
                            }}
                          >
                            {/* report-details-header */}
                            <header className="General-Header Box-Shadow">
                              <Row>
                                <Col span={16} className="alignC">
                                  <Dropdown
                                    overlay={classesMenu}
                                    overlayClassName="Filters-Menu"
                                    open={
                                      secondaryFiltersVisibility.classesMenu
                                    }
                                    onVisibleChange={(event) =>
                                      this.changeStateValue_L2(
                                        "secondaryFiltersVisibility",
                                        changeSwitch,
                                        "classesMenu",
                                        event,
                                      )
                                    }
                                  >
                                    <Button
                                      className={`${
                                        secondarySelectedFilters?.filterClasses
                                          .length > 0
                                          ? "Filters-Button-Selected"
                                          : "Filters-Button-Empty"
                                      }`}
                                    >
                                      Classes
                                      <DownOutlined />
                                    </Button>
                                  </Dropdown>
                                  {changeSwitch !== "ipIndia" && (
                                    <Dropdown
                                      overlay={stateMenu}
                                      overlayClassName="Filters-Menu"
                                      open={
                                        secondaryFiltersVisibility.stateMenu
                                      }
                                      onVisibleChange={(event) =>
                                        this.changeStateValue_L2(
                                          "secondaryFiltersVisibility",
                                          changeSwitch,
                                          "stateMenu",
                                          event,
                                        )
                                      }
                                    >
                                      <Button
                                        className={`${
                                          secondarySelectedFilters?.filterState
                                            .length > 0
                                            ? "Filters-Button-Selected"
                                            : "Filters-Button-Empty"
                                        }`}
                                      >
                                        States
                                        <DownOutlined />
                                      </Button>
                                    </Dropdown>
                                  )}
                                  {changeSwitch !== "ipIndia" && (
                                    <Dropdown
                                      overlay={officeMenu}
                                      overlayClassName="Filters-Menu"
                                      open={
                                        secondaryFiltersVisibility.officeMenu
                                      }
                                      onVisibleChange={(event) =>
                                        this.changeStateValue_L2(
                                          "secondaryFiltersVisibility",
                                          changeSwitch,
                                          "officeMenu",
                                          event,
                                        )
                                      }
                                    >
                                      <Button
                                        className={`${
                                          secondarySelectedFilters?.filterOffice
                                            .length > 0
                                            ? "Filters-Button-Selected"
                                            : "Filters-Button-Empty"
                                        }`}
                                      >
                                        Offices
                                        <DownOutlined />
                                      </Button>
                                    </Dropdown>
                                  )}
                                  {changeSwitch !== "ipIndia" && (
                                    <Dropdown
                                      overlay={categoryMenu}
                                      overlayClassName="Filters-Menu"
                                      open={
                                        secondaryFiltersVisibility.categoryMenu
                                      }
                                      onVisibleChange={(event) =>
                                        this.changeStateValue_L2(
                                          "secondaryFiltersVisibility",
                                          changeSwitch,
                                          "categoryMenu",
                                          event,
                                        )
                                      }
                                    >
                                      <Button
                                        className={`${
                                          secondarySelectedFilters
                                            ?.filterCategory.length > 0
                                            ? "Filters-Button-Selected"
                                            : "Filters-Button-Empty"
                                        }`}
                                      >
                                        Categories
                                        <DownOutlined />
                                      </Button>
                                    </Dropdown>
                                  )}
                                  {changeSwitch !== "ipIndia" && (
                                    <Dropdown
                                      overlay={typeMenu}
                                      overlayClassName="Filters-Menu"
                                      open={secondaryFiltersVisibility.typeMenu}
                                      onVisibleChange={(event) =>
                                        this.changeStateValue_L2(
                                          "secondaryFiltersVisibility",
                                          changeSwitch,
                                          "typeMenu",
                                          event,
                                        )
                                      }
                                    >
                                      <Button
                                        className={`${
                                          secondarySelectedFilters?.filterType
                                            .length > 0
                                            ? "Filters-Button-Selected"
                                            : "Filters-Button-Empty"
                                        }`}
                                      >
                                        Types
                                        <DownOutlined />
                                      </Button>
                                    </Dropdown>
                                  )}
                                  <Dropdown
                                    overlay={statusMenu}
                                    overlayClassName="Filters-Menu"
                                    open={secondaryFiltersVisibility.statusMenu}
                                    onVisibleChange={(event) =>
                                      this.changeStateValue_L2(
                                        "secondaryFiltersVisibility",
                                        changeSwitch,
                                        "statusMenu",
                                        event,
                                      )
                                    }
                                  >
                                    <Button
                                      className={`${
                                        secondarySelectedFilters?.filterStatus
                                          .length > 0
                                          ? "Filters-Button-Selected"
                                          : "Filters-Button-Empty"
                                      }`}
                                    >
                                      Statuses
                                      <DownOutlined />
                                    </Button>
                                  </Dropdown>
                                  {changeSwitch === "ipIndia" && (
                                    <Dropdown
                                      overlay={proprietorNameMenu}
                                      overlayClassName="Filters-Menu"
                                      open={
                                        secondaryFiltersVisibility.proprietorNameMenu
                                      }
                                      onVisibleChange={(event) =>
                                        this.changeStateValue_L2(
                                          "secondaryFiltersVisibility",
                                          changeSwitch,
                                          "proprietorNameMenu",
                                          event,
                                        )
                                      }
                                    >
                                      <Button
                                        className={`${
                                          secondarySelectedFilters
                                            ?.filterProprietorName.length > 0
                                            ? "Filters-Button-Selected"
                                            : "Filters-Button-Empty"
                                        }`}
                                      >
                                        Proprietors
                                        <DownOutlined />
                                      </Button>
                                    </Dropdown>
                                  )}
                                  {changeSwitch !== "ipIndia" && (
                                    <Dropdown
                                      overlay={proprietorMenu}
                                      overlayClassName="Filters-Menu"
                                      open={
                                        secondaryFiltersVisibility.proprietorMenu
                                      }
                                      onVisibleChange={(event) =>
                                        this.changeStateValue_L2(
                                          "secondaryFiltersVisibility",
                                          changeSwitch,
                                          "proprietorMenu",
                                          event,
                                        )
                                      }
                                    >
                                      <Button
                                        className={`${
                                          secondarySelectedFilters
                                            ?.filterProprietor.length > 0
                                            ? "Filters-Button-Selected"
                                            : "Filters-Button-Empty"
                                        }`}
                                      >
                                        Proprietors
                                        <DownOutlined />
                                      </Button>
                                    </Dropdown>
                                  )}
                                  {changeSwitch === "ipIndia" && (
                                    <Dropdown
                                      overlay={journalNumberMenu}
                                      overlayClassName="Filters-Menu"
                                      open={
                                        secondaryFiltersVisibility.journalNumberMenu
                                      }
                                      onVisibleChange={(event) =>
                                        this.changeStateValue_L2(
                                          "secondaryFiltersVisibility",
                                          changeSwitch,
                                          "journalNumberMenu",
                                          event,
                                        )
                                      }
                                    >
                                      <Button
                                        className={`${
                                          secondarySelectedFilters?.filterJournalNumber &&
                                          secondarySelectedFilters
                                            ?.filterJournalNumber.length > 0
                                            ? "Filters-Button-Selected"
                                            : "Filters-Button-Empty"
                                        }`}
                                      >
                                        Journals
                                        <DownOutlined />
                                      </Button>
                                    </Dropdown>
                                  )}
                                  {
                                    // changeSwitch === "ipIndia" &&
                                    // <Dropdown
                                    //     overlay={logicTypeMenu}
                                    //     overlayClassName="Filters-Menu"
                                    //     visible={secondaryFiltersVisibility.logicTypeMenu}
                                    //     onVisibleChange={(event) => this.changeStateValue_L2('secondaryFiltersVisibility', changeSwitch, 'logicTypeMenu', event)}
                                    // >
                                    //     <Button className={`${(secondarySelectedFilters?.filterLogicType && secondarySelectedFilters?.filterLogicType.length > 0) ? 'Filters-Button-Selected' : 'Filters-Button-Empty'}`}>
                                    //         Logic<Icon type="down" />
                                    //     </Button>
                                    // </Dropdown>
                                  }
                                  <Dropdown
                                    overlay={
                                      <DateRangePicker
                                        type={"Date of Application"}
                                        startValue={
                                          secondarySelectedFilters?.date_of_application_startValue
                                        }
                                        endValue={
                                          secondarySelectedFilters?.date_of_application_endValue
                                        }
                                        setDate={(val) => this.setDate1(val)}
                                        range={this.state.range1}
                                        handleDateString={(
                                          startValue,
                                          endValue,
                                          dateString,
                                        ) =>
                                          this.setState({
                                            secondarySelectedFilters: {
                                              ...this.state
                                                .secondarySelectedFilters,
                                              [changeSwitch]: {
                                                ...this.state
                                                  .secondarySelectedFilters[
                                                  changeSwitch
                                                ],
                                                date_of_application_startValue:
                                                  startValue,
                                                date_of_application_endValue:
                                                  endValue,
                                                date_of_application:
                                                  startValue !== null ||
                                                  endValue !== null
                                                    ? dateString
                                                    : null,
                                              },
                                            },
                                          })
                                        }
                                      />
                                    }
                                    overlayClassName="filter-menu"
                                  >
                                    <Button
                                      className={`${
                                        secondarySelectedFilters
                                          ?.date_of_application.length > 0
                                          ? "Filters-Button-Selected"
                                          : "Filters-Button-Empty"
                                      }`}
                                    >
                                      DoA <DownOutlined />
                                    </Button>
                                  </Dropdown>
                                  <Dropdown
                                    overlay={
                                      <>
                                        <DateRangePicker
                                          type={"Date of Usage"}
                                          startValue={
                                            secondarySelectedFilters?.date_of_usage_startValue
                                          }
                                          endValue={
                                            secondarySelectedFilters?.date_of_usage_endValue
                                          }
                                          setDate={(val) => this.setDate2(val)}
                                          range={this.state.range2}
                                          handleDateString={(
                                            startValue,
                                            endValue,
                                            dateString,
                                          ) =>
                                            this.setState({
                                              secondarySelectedFilters: {
                                                ...this.state
                                                  .secondarySelectedFilters,
                                                [changeSwitch]: {
                                                  ...this.state
                                                    .secondarySelectedFilters[
                                                    changeSwitch
                                                  ],
                                                  date_of_usage_startValue:
                                                    startValue,
                                                  date_of_usage_endValue:
                                                    endValue,
                                                  date_of_usage:
                                                    startValue !== null ||
                                                    endValue !== null
                                                      ? dateString
                                                      : null,
                                                },
                                              },
                                            })
                                          }
                                        />
                                        <div
                                          style={{
                                            textAlign: "center",
                                            lineHeight: "32px",
                                          }}
                                        >
                                          <Checkbox
                                            checked={
                                              this.state
                                                .secondarySelectedFilters[
                                                changeSwitch
                                              ].date_of_usage_proposed
                                            }
                                            onChange={(event) =>
                                              this.changeStateValue_L2(
                                                "secondarySelectedFilters",
                                                changeSwitch,
                                                "date_of_usage_proposed",
                                                event.target.checked,
                                              )
                                            }
                                          >
                                            Proposed
                                          </Checkbox>
                                        </div>
                                      </>
                                    }
                                    overlayClassName="filter-menu"
                                  >
                                    <Button
                                      className={`${
                                        secondarySelectedFilters?.date_of_usage
                                          .length > 0 ||
                                        secondarySelectedFilters?.date_of_usage_proposed
                                          ? "Filters-Button-Selected"
                                          : "Filters-Button-Empty"
                                      }`}
                                    >
                                      DoU <DownOutlined />
                                    </Button>
                                  </Dropdown>
                                  <Search
                                    placeholder="Search application number or trademark name"
                                    onSearch={(searchInput) =>
                                      this.handleApplySecondaryFilters(
                                        searchInput,
                                        data,
                                      )
                                    }
                                    enterButton={
                                      <Button
                                        style={{
                                          backgroundColor: "#4285f4",
                                          color: "#ffffff",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Apply
                                      </Button>
                                    }
                                    style={{ width: "20vw", minWidth: "150px" }}
                                  />
                                  <Button
                                    type="link"
                                    onClick={() =>
                                      this.handleResetSecondaryFilters(data)
                                    }
                                  >
                                    Reset
                                  </Button>
                                  <Cascader
                                    options={[
                                      {
                                        value: "ASC",
                                        label: "Ascending",
                                        children: [
                                          {
                                            value: "CLASS",
                                            label: "Class",
                                          },
                                          {
                                            value: "APPLICATION NUMBER",
                                            label: "Application Number",
                                          },
                                          {
                                            value: "APPLIED FOR",
                                            label: "Applied For",
                                          },
                                          {
                                            value: "STATUS",
                                            label: "Status",
                                          },
                                          {
                                            value: "DATE OF APPLICATION",
                                            label: "Date of Application",
                                          },
                                          {
                                            value: "DATE OF USAGE",
                                            label: "Date of Usage",
                                          },
                                        ],
                                      },
                                      {
                                        value: "DESC",
                                        label: "Descending",
                                        children: [
                                          {
                                            value: "CLASS",
                                            label: "Class",
                                          },
                                          {
                                            value: "APPLICATION NUMBER",
                                            label: "Application Number",
                                          },
                                          {
                                            value: "APPLIED FOR",
                                            label: "Applied For",
                                          },
                                          {
                                            value: "STATUS",
                                            label: "Status",
                                          },
                                          {
                                            value: "DATE OF APPLICATION",
                                            label: "Date of Application",
                                          },
                                          {
                                            value: "DATE OF USAGE",
                                            label: "Date of Usage",
                                          },
                                        ],
                                      },
                                      {
                                        value: "CLEAR",
                                        label: "Clear All",
                                        children: [],
                                      },
                                    ]}
                                    allowClear={false}
                                    placeholder="Sorting"
                                    expandTrigger="hover"
                                    value={secondarySorting.sortValue}
                                    onChange={(value) =>
                                      this.handleApplySecondarySorting(
                                        value,
                                        data,
                                      )
                                    }
                                  />
                                </Col>
                                <Col span={8} className="alignR">
                                  <div
                                    style={{
                                      padding: "5px",
                                      display: "inline-block",
                                    }}
                                  >
                                    {changeSwitch === "ipIndia" && (
                                      <Tooltip
                                        title="Add to Trademark Reports"
                                        placement="bottom"
                                      >
                                        <span
                                          style={{
                                            padding: "0px 5px",
                                            display: "inline-block",
                                          }}
                                        >
                                          <Badge
                                            overflowCount={9999}
                                            count={
                                              customReportData?.data[
                                                expandRecord
                                              ]?.select_all
                                                ? changeSwitch === "ipIndia"
                                                  ? ""
                                                  : "All"
                                                : customReportData?.data[
                                                      expandRecord
                                                    ][
                                                      changeSwitch === "ipIndia"
                                                        ? "realSelectedMark"
                                                        : "selectedMark"
                                                    ]
                                                  ? customReportData?.data[
                                                      expandRecord
                                                    ][
                                                      changeSwitch === "ipIndia"
                                                        ? "realSelectedMark"
                                                        : "selectedMark"
                                                    ]?.length
                                                  : ""
                                            }
                                            style={{ marginTop: "3px" }}
                                          >
                                            <Button
                                              ghost
                                              style={{ padding: "0px 5px" }}
                                              type="primary"
                                              disabled={
                                                changeSwitch === "ipIndia"
                                                  ? customReportData?.data[
                                                      expandRecord
                                                    ]?.select_all ||
                                                    customReportData?.data[
                                                      expandRecord
                                                    ]?.selectedMark.length === 0
                                                    ? true
                                                    : false
                                                  : customReportData?.data[
                                                        expandRecord
                                                      ]?.select_all ||
                                                      customReportData?.data[
                                                        expandRecord
                                                      ]?.selectedMark.length > 0
                                                    ? false
                                                    : true
                                              }
                                              onClick={() => {
                                                this.props.getTrademarkReportStatus(
                                                  {
                                                    check: true,
                                                    report_source: "MARK",
                                                    mark_id: data.mark_id,
                                                  },
                                                  RM_GET_REPORT_AVAILABILITY,
                                                );
                                                this.setState({
                                                  drawersVisibility: {
                                                    ...this.state
                                                      .drawersVisibility,
                                                    addToReport: data.mark_id,
                                                  },
                                                  customReportData: {
                                                    ...this.state
                                                      .customReportData,
                                                    [changeSwitch]: {
                                                      ...this.state
                                                        .customReportData[
                                                        changeSwitch
                                                      ],
                                                      data: {
                                                        ...this.state
                                                          .customReportData[
                                                          changeSwitch
                                                        ].data,
                                                        [expandRecord]: {
                                                          ...this.state
                                                            .customReportData[
                                                            changeSwitch
                                                          ].data[
                                                            `${expandRecord}`
                                                          ],
                                                        },
                                                      },
                                                      id: expandRecord,
                                                      report_source: "MARK",
                                                      operation: "APPEND",
                                                      report_file: true,
                                                      report_type: "URGENT",
                                                      report_file_name: "",
                                                      report_file_types: [],
                                                    },
                                                  },
                                                });
                                              }}
                                            >
                                              Add
                                            </Button>
                                          </Badge>
                                        </span>
                                      </Tooltip>
                                    )}
                                    <span
                                      style={{
                                        padding: "0px 5px",
                                        display: "inline-block",
                                      }}
                                    >
                                      <Badge
                                        overflowCount={9999}
                                        count={
                                          customReportData?.data[expandRecord]
                                            ?.select_all
                                            ? "All"
                                            : customReportData?.data[
                                                  expandRecord
                                                ][
                                                  changeSwitch === "ipIndia"
                                                    ? "realSelectedMark"
                                                    : "selectedMark"
                                                ]
                                              ? customReportData?.data[
                                                  expandRecord
                                                ][
                                                  changeSwitch === "ipIndia"
                                                    ? "realSelectedMark"
                                                    : "selectedMark"
                                                ].length
                                              : ""
                                        }
                                        style={{ marginTop: "3px" }}
                                      >
                                        <Button
                                          ghost
                                          style={{ padding: "0px 5px" }}
                                          type="primary"
                                          disabled={
                                            customReportData?.data[expandRecord]
                                              ?.select_all ||
                                            customReportData?.data[expandRecord]
                                              ?.selectedMark?.length > 0
                                              ? false
                                              : true
                                          }
                                          onClick={() =>
                                            this.setState({
                                              drawersVisibility: {
                                                ...this.state.drawersVisibility,
                                                createReport: true,
                                              },
                                              customReportData: {
                                                ...this.state.customReportData,
                                                [changeSwitch]: {
                                                  ...this.state
                                                    .customReportData[
                                                    changeSwitch
                                                  ],
                                                  data: {
                                                    ...this.state
                                                      .customReportData[
                                                      changeSwitch
                                                    ].data,
                                                    [expandRecord]: {
                                                      ...this.state
                                                        .customReportData[
                                                        changeSwitch
                                                      ].data[`${expandRecord}`],
                                                    },
                                                  },
                                                  id: expandRecord,
                                                  report_source:
                                                    changeSwitch ===
                                                    "imageSearch"
                                                      ? "IMAGE_SEARCH"
                                                      : changeSwitch.toUpperCase(),
                                                  operation: "DOWNLOAD",
                                                  report_file: true,
                                                  report_file_name: "",
                                                  report_file_types:
                                                    changeSwitch === "ipIndia"
                                                      ? ["EXCEL"]
                                                      : ["PDF"],
                                                },
                                              },
                                            })
                                          }
                                        >
                                          Create Report
                                        </Button>
                                      </Badge>
                                    </span>
                                    <span
                                      style={{
                                        padding: "0px 5px",
                                        display: "inline-block",
                                      }}
                                    >
                                      <Popover
                                        trigger="click"
                                        visible={this.state.deleteMarkVisible}
                                        onVisibleChange={(visible) =>
                                          this.changeStateValue_L0(
                                            "deleteMarkVisible",
                                            visible,
                                          )
                                        }
                                        content={
                                          <div>
                                            <Text>
                                              Do you want to delete selected
                                              marks
                                            </Text>
                                            <div className="m-top-10 alignC">
                                              <Button
                                                style={{ margin: "0px 5px" }}
                                                onClick={() =>
                                                  this.changeStateValue_L0(
                                                    "deleteMarkVisible",
                                                    false,
                                                  )
                                                }
                                              >
                                                Cancel
                                              </Button>
                                              <Button
                                                danger
                                                style={{ margin: "0px 5px" }}
                                                loading={
                                                  this.props
                                                    .updateReportMarkStatus ===
                                                  LOADING
                                                }
                                                onClick={() =>
                                                  this.handleDeleteReportMark(
                                                    expandRecord,
                                                  )
                                                }
                                              >
                                                Confirm
                                              </Button>
                                            </div>
                                          </div>
                                        }
                                      >
                                        <Badge
                                          overflowCount={9999}
                                          count={
                                            customReportData?.data[expandRecord]
                                              ?.select_all
                                              ? "All"
                                              : customReportData?.data[
                                                  expandRecord
                                                ][
                                                  changeSwitch === "ipIndia"
                                                    ? "realSelectedMark"
                                                    : "selectedMark"
                                                ]?.length
                                          }
                                          style={{ marginTop: "3px" }}
                                        >
                                          <Button
                                            ghost
                                            style={{ padding: "0px 5px" }}
                                            danger
                                            disabled={
                                              customReportData?.data[
                                                expandRecord
                                              ]?.select_all ||
                                              customReportData?.data[
                                                expandRecord
                                              ]?.selectedMark?.length > 0
                                                ? false
                                                : true
                                            }
                                          >
                                            Delete Mark
                                          </Button>
                                        </Badge>
                                      </Popover>
                                    </span>
                                    <MinusCircleOutlined
                                      style={{
                                        margin: "0px 5px",
                                        fontSize: "14px",
                                        visibility: `${
                                          customReportData?.data[expandRecord]
                                            ?.selectedMark?.length > 0
                                            ? ""
                                            : "hidden"
                                        }`,
                                      }}
                                      onClick={() =>
                                        this.setState({
                                          customReportData: {
                                            ...this.state.customReportData,
                                            [changeSwitch]: {
                                              ...this.state.customReportData[
                                                changeSwitch
                                              ],
                                              data: {
                                                ...this.state.customReportData[
                                                  changeSwitch
                                                ].data,
                                                [expandRecord]: {
                                                  ...this.state
                                                    .customReportData[
                                                    changeSwitch
                                                  ].data[`${expandRecord}`],
                                                  ...Report_defaultInitialReport,
                                                },
                                              },
                                            },
                                          },
                                        })
                                      }
                                    />
                                  </div>
                                  <div style={{ display: "inline-block" }}>
                                    <Dropdown
                                      overlay={moreActionsMenu}
                                      overlayClassName="Filters-Menu"
                                      open={
                                        secondaryFiltersVisibility.moreActionsMenu
                                      }
                                      onVisibleChange={(event) =>
                                        this.changeStateValue_L2(
                                          "secondaryFiltersVisibility",
                                          changeSwitch,
                                          "moreActionsMenu",
                                          event,
                                        )
                                      }
                                    >
                                      <Button className="Search-More-Actions">
                                        <UnorderedListOutlined
                                          style={{
                                            fontSize: "16px",
                                            position: "relative",
                                            bottom: "2px",
                                          }}
                                        />
                                      </Button>
                                    </Dropdown>
                                  </div>
                                </Col>
                              </Row>
                            </header>
                            {/* report-details-header */}

                            {/* report-details-content */}
                            <Row>
                              <Col span={24}>
                                {reportDetailStatus === LOADING && (
                                  <div
                                    className="spin-container"
                                    style={{ height: "calc(100vh - 290px)" }}
                                  >
                                    <Spin size="large" />
                                  </div>
                                )}
                                {reportDetailStatus === SUCCESS && (
                                  <>
                                    {/* report-details-card-view */}
                                    <div
                                      className={`General-Content Flex-Content vertical_scroll ${
                                        selectedView === "card"
                                          ? ""
                                          : "display_none"
                                      }`}
                                      style={{
                                        height: "calc(100vh - 290px)",
                                        overflowX: "hidden",
                                        overflowY: "auto",
                                      }}
                                    >
                                      {reportDetail &&
                                        reportDetail?.marks.map((eachMark) => (
                                          <GeneralMarkCard
                                            key={eachMark.id}
                                            eachMark={eachMark}
                                            onSelectMark={(eachMark, checked) =>
                                              this.handleSecondarySelectedMark(
                                                eachMark,
                                                checked,
                                                expandRecord,
                                              )
                                            }
                                            checked={
                                              customReportData.data[
                                                expandRecord
                                              ]
                                                ? changeSwitch === "ipIndia"
                                                  ? customReportData?.data[
                                                      expandRecord
                                                    ]?.realSelectedMark?.includes(
                                                      eachMark.real_trademark_id,
                                                    ) ||
                                                    customReportData?.data[
                                                      expandRecord
                                                    ].select_all
                                                  : customReportData?.data[
                                                      expandRecord
                                                    ]?.selectedMark?.includes(
                                                      eachMark.id,
                                                    ) ||
                                                    customReportData?.data[
                                                      expandRecord
                                                    ].select_all
                                                : false
                                            }
                                            selectAllCards={
                                              changeSwitch === "ipIndia"
                                                ? !eachMark.real_trademark_id ||
                                                  (customReportData?.data[
                                                    expandRecord
                                                  ] &&
                                                    customReportData?.data[
                                                      expandRecord
                                                    ].select_all)
                                                : customReportData?.data[
                                                    expandRecord
                                                  ] &&
                                                  customReportData?.data[
                                                    expandRecord
                                                  ].select_all
                                            }
                                            changeStateValue_L1={(
                                              x,
                                              y,
                                              application,
                                            ) => {
                                              sessionStorage.setItem(
                                                "application",
                                                application,
                                              );
                                              window.open(
                                                `/search/trademark-profile`,
                                              );
                                            }}
                                            styleCard={{ textAlign: "left" }}
                                            similarity={null}
                                            status={checkNA(eachMark.status)}
                                            proprietorClickable={
                                              changeSwitch === "ipIndia"
                                                ? false
                                                : true
                                            }
                                            onProprietorClick={(proprietor) => {
                                              if (changeSwitch !== "ipIndia") {
                                                sessionStorage.setItem(
                                                  "proprietor",
                                                  proprietor,
                                                );
                                                window.open(
                                                  `/search/proprietor-profile/${proprietor}`,
                                                );
                                              }
                                            }}
                                            proprietorFlat={
                                              changeSwitch === "ipIndia"
                                            }
                                            dataAsOn={
                                              changeSwitch === "ipIndia"
                                            }
                                            classesFlat={
                                              changeSwitch === "ipIndia"
                                            }
                                          />
                                        ))}
                                      {reportDetail &&
                                        reportDetail?.marks?.length === 0 && (
                                          <div
                                            className="empty-container"
                                            style={{ width: "100%" }}
                                          >
                                            <Empty />
                                          </div>
                                        )}
                                    </div>
                                    {/* report-details-card-view */}

                                    {/* report-details-table-view */}
                                    <div
                                      className={`General-Content Flex-Content vertical_scroll ${
                                        selectedView === "table"
                                          ? ""
                                          : "display_none"
                                      }`}
                                      style={{
                                        height: "calc(100vh - 290px)",
                                        overflowX: "hidden",
                                        overflowY: "auto",
                                      }}
                                    >
                                      <table className="Table">
                                        <thead className="Table-Head">
                                          <tr className="Table-Head-Row">
                                            <th
                                              className="Table-Head-Cell Black-Shadow"
                                              style={{
                                                width: "4%",
                                                minWidth: "50px",
                                              }}
                                            ></th>
                                            <th className="Table-Head-Cell Black-Shadow">
                                              TRADEMARK
                                            </th>
                                            {changeSwitch === "ipIndia" && (
                                              <th className="Table-Head-Cell Black-Shadow">
                                                DATA AS ON
                                              </th>
                                            )}
                                            <th className="Table-Head-Cell Black-Shadow">
                                              STATUS
                                            </th>
                                            <th className="Table-Head-Cell Black-Shadow">
                                              TYPE
                                            </th>
                                            <th className="Table-Head-Cell Black-Shadow">
                                              PROPRIETORS
                                            </th>
                                            <th className="Table-Head-Cell Black-Shadow">
                                              CLASSES
                                            </th>
                                            <th className="Table-Head-Cell Black-Shadow">
                                              DoA | DoU
                                            </th>
                                            <th
                                              className="Table-Head-Cell Black-Shadow"
                                              style={{
                                                width: "4%",
                                                minWidth: "50px",
                                              }}
                                            >
                                              {this.state
                                                .tableDescriptionRowsVisibility ? (
                                                <Tooltip
                                                  placement="left"
                                                  title="Hide Descriptions"
                                                >
                                                  <MinusSquareFilled
                                                    style={{
                                                      fontSize: "20px",
                                                      verticalAlign: "baseline",
                                                    }}
                                                    onClick={() =>
                                                      this.setState({
                                                        tableDescriptionRowsVisibility: false,
                                                      })
                                                    }
                                                  />
                                                </Tooltip>
                                              ) : (
                                                <Tooltip
                                                  placement="left"
                                                  title="Show Descriptions"
                                                >
                                                  <PlusSquareFilled
                                                    style={{
                                                      fontSize: "20px",
                                                      verticalAlign: "baseline",
                                                    }}
                                                    onClick={() =>
                                                      this.setState({
                                                        tableDescriptionRowsVisibility: true,
                                                      })
                                                    }
                                                  />
                                                </Tooltip>
                                              )}
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody className="Table-Body">
                                          {reportDetail &&
                                            reportDetail?.marks.map(
                                              (eachMark, rowIndex) => {
                                                return (
                                                  <React.Fragment
                                                    key={eachMark.id}
                                                  >
                                                    <tr
                                                      className={`Table-Body-Row ${
                                                        rowIndex % 2 === 0
                                                          ? "Row-Even"
                                                          : "Row-Odd"
                                                      }`}
                                                    >
                                                      <td>
                                                        <Checkbox
                                                          onChange={(event) =>
                                                            this.handleSecondarySelectedMark(
                                                              eachMark,
                                                              event.target
                                                                .checked,
                                                              expandRecord,
                                                            )
                                                          }
                                                          checked={
                                                            customReportData
                                                              .data[
                                                              expandRecord
                                                            ]
                                                              ? changeSwitch ===
                                                                "ipIndia"
                                                                ? customReportData?.data[
                                                                    expandRecord
                                                                  ].realSelectedMark?.includes(
                                                                    eachMark.real_trademark_id,
                                                                  ) ||
                                                                  customReportData
                                                                    ?.data[
                                                                    expandRecord
                                                                  ].select_all
                                                                : customReportData?.data[
                                                                    expandRecord
                                                                  ].selectedMark?.includes(
                                                                    eachMark.id,
                                                                  ) ||
                                                                  customReportData
                                                                    ?.data[
                                                                    expandRecord
                                                                  ].select_all
                                                              : false
                                                          }
                                                          disabled={
                                                            changeSwitch ===
                                                            "ipIndia"
                                                              ? !eachMark.real_trademark_id ||
                                                                (customReportData
                                                                  ?.data[
                                                                  expandRecord
                                                                ] &&
                                                                  customReportData
                                                                    ?.data[
                                                                    expandRecord
                                                                  ].select_all)
                                                              : customReportData
                                                                  ?.data[
                                                                  expandRecord
                                                                ] &&
                                                                customReportData
                                                                  ?.data[
                                                                  expandRecord
                                                                ].select_all
                                                          }
                                                        />
                                                      </td>
                                                      <td>
                                                        <span>
                                                          {eachMark.applied_for}
                                                        </span>
                                                        <br />
                                                        {eachMark.associated_image && (
                                                          <>
                                                            <span>
                                                              <img
                                                                src={
                                                                  eachMark.associated_image
                                                                }
                                                                height="70px"
                                                                width="70px"
                                                                alt=""
                                                              />
                                                            </span>
                                                            <br />
                                                          </>
                                                        )}
                                                        <span
                                                          className="Application-Number"
                                                          onClick={() => {
                                                            sessionStorage.setItem(
                                                              "application",
                                                              eachMark.application_number,
                                                            );
                                                            window.open(
                                                              `/search/trademark-profile`,
                                                            );
                                                          }}
                                                        >
                                                          {
                                                            eachMark.application_number
                                                          }
                                                        </span>
                                                      </td>
                                                      {changeSwitch ===
                                                        "ipIndia" && (
                                                        <td>
                                                          {eachMark.data_as_on
                                                            ? eachMark.data_as_on
                                                            : "NA"}
                                                        </td>
                                                      )}
                                                      <td>
                                                        {eachMark.status
                                                          ? eachMark.status
                                                          : "NA"}
                                                      </td>
                                                      <td>
                                                        {eachMark.type
                                                          ? eachMark?.type?.name
                                                          : "NA"}
                                                      </td>
                                                      <td>
                                                        {changeSwitch ===
                                                          "ipIndia" && (
                                                          <span>
                                                            <span>
                                                              <strong>
                                                                Proprietor:{" "}
                                                              </strong>
                                                              <span
                                                                style={{
                                                                  padding:
                                                                    "0px 5px",
                                                                }}
                                                              >
                                                                {
                                                                  eachMark.proprietor_name
                                                                }
                                                              </span>
                                                            </span>
                                                            <br />
                                                            <span>
                                                              <strong>
                                                                Address:{" "}
                                                              </strong>
                                                              <span
                                                                style={{
                                                                  padding:
                                                                    "0px 5px",
                                                                }}
                                                              >
                                                                {
                                                                  eachMark.proprietor_address
                                                                }
                                                              </span>
                                                            </span>
                                                          </span>
                                                        )}
                                                        {changeSwitch !==
                                                          "ipIndia" &&
                                                          eachMark.proprietor.map(
                                                            (
                                                              eachProprietor,
                                                              index,
                                                              allProprietors,
                                                            ) => (
                                                              <React.Fragment
                                                                key={`${eachMark.id}-${eachProprietor.id}`}
                                                              >
                                                                <span>
                                                                  <span>
                                                                    <strong>
                                                                      Proprietor:{" "}
                                                                    </strong>
                                                                    <span
                                                                      style={{
                                                                        padding:
                                                                          "0px 5px",
                                                                      }}
                                                                      className={`${
                                                                        changeSwitch !==
                                                                        "ipIndia"
                                                                          ? "Application-Number"
                                                                          : ""
                                                                      }`}
                                                                      onClick={() => {
                                                                        if (
                                                                          changeSwitch !==
                                                                          "ipIndia"
                                                                        ) {
                                                                          sessionStorage.setItem(
                                                                            "proprietor",
                                                                            eachProprietor.id,
                                                                          );
                                                                          window.open(
                                                                            `/search/proprietor-profile/${eachProprietor.id}`,
                                                                          );
                                                                        }
                                                                      }}
                                                                    >
                                                                      {
                                                                        eachProprietor.name
                                                                      }
                                                                    </span>
                                                                  </span>
                                                                  <br />
                                                                  <span>
                                                                    <strong>
                                                                      Address:{" "}
                                                                    </strong>
                                                                    <span
                                                                      style={{
                                                                        padding:
                                                                          "0px 5px",
                                                                      }}
                                                                    >
                                                                      {
                                                                        eachProprietor.address
                                                                      }
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                {allProprietors.length ===
                                                                index + 1 ? (
                                                                  ""
                                                                ) : (
                                                                  <hr />
                                                                )}
                                                              </React.Fragment>
                                                            ),
                                                          )}
                                                      </td>
                                                      <td>
                                                        {changeSwitch ===
                                                          "ipIndia" &&
                                                          `${eachMark.associated_class}`}
                                                        {changeSwitch !==
                                                          "ipIndia" &&
                                                          eachMark.associated_class.map(
                                                            (
                                                              eachClass,
                                                              index,
                                                              allClasses,
                                                            ) =>
                                                              allClasses.length ===
                                                              index + 1
                                                                ? `${eachClass}`
                                                                : `${eachClass}, `,
                                                          )}
                                                      </td>
                                                      <td>
                                                        <span>
                                                          DoA:{" "}
                                                          {eachMark.application_date
                                                            ? eachMark.application_date
                                                            : ""}
                                                        </span>
                                                        <br />
                                                        <span>
                                                          DoU:{" "}
                                                          {eachMark.date_of_usage
                                                            ? eachMark.date_of_usage
                                                            : ""}
                                                        </span>
                                                      </td>
                                                      <td></td>
                                                    </tr>
                                                    {this.state
                                                      .tableDescriptionRowsVisibility && (
                                                      <tr
                                                        className={`Table-Body-Row ${
                                                          rowIndex % 2 === 0
                                                            ? "Row-Even"
                                                            : "Row-Odd"
                                                        }`}
                                                      >
                                                        <td
                                                          colSpan={
                                                            changeSwitch ===
                                                            "ipIndia"
                                                              ? "9"
                                                              : "8"
                                                          }
                                                          style={{
                                                            verticalAlign:
                                                              "top",
                                                            padding: 10,
                                                          }}
                                                        >
                                                          {eachMark.description
                                                            ? eachMark.description
                                                            : "Description"}
                                                        </td>
                                                      </tr>
                                                    )}
                                                  </React.Fragment>
                                                );
                                              },
                                            )}
                                          {reportDetail &&
                                            reportDetail?.marks?.length ===
                                              0 && (
                                              <tr>
                                                <td
                                                  colSpan={
                                                    changeSwitch === "ipIndia"
                                                      ? "9"
                                                      : "8"
                                                  }
                                                >
                                                  <div
                                                    className="empty-container"
                                                    style={{
                                                      height:
                                                        "calc(100vh - 325px)",
                                                      width: "100%",
                                                    }}
                                                  >
                                                    <Empty />
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                    {/* report-details-table-view */}
                                  </>
                                )}
                                {reportDetailStatus === ERROR && (
                                  <div
                                    className="error-container"
                                    style={{ height: "calc(100vh - 290px)" }}
                                  >
                                    <Title level={4}>
                                      unfortunately an error has occurred please
                                      try again later. will connect you shortly.
                                    </Title>
                                  </div>
                                )}
                              </Col>
                            </Row>
                            {/* report-details-content */}

                            {/* report-details-footer */}
                            <footer className="General-Footer Box-Shadow">
                              <Row>
                                <Col span={24}>
                                  <Radio.Group
                                    onChange={(event) =>
                                      this.changeStateValue_L1(
                                        "selectedView",
                                        changeSwitch,
                                        event.target.value,
                                      )
                                    }
                                    value={selectedView}
                                    style={{ float: "left" }}
                                  >
                                    <Radio.Button value="card">
                                      Cards
                                    </Radio.Button>
                                    <Radio.Button value="table">
                                      Table
                                    </Radio.Button>
                                  </Radio.Group>
                                  <Pagination
                                    showQuickJumper
                                    defaultCurrent={1}
                                    current={secondaryPageNumber}
                                    total={reportDetail && reportDetail.count}
                                    showTotal={(total, range) =>
                                      `${range[0]}-${range[1]} of ${total} `
                                    }
                                    pageSize={100}
                                    showSizeChanger={false}
                                    onChange={(pageNumber) =>
                                      this.handleSecondaryPageChange(
                                        pageNumber,
                                        data,
                                      )
                                    }
                                  />
                                </Col>
                              </Row>
                            </footer>
                            {/* report-details-footer */}
                          </div>
                        )}
                        {/* report-details */}
                      </React.Fragment>
                    ))}
                  {report && report.reports.length === 0 && (
                    <div
                      className="empty-container"
                      style={{ height: "calc(100vh - 100px)" }}
                    >
                      <Empty />
                    </div>
                  )}
                </>
              )}
              {reportStatus === ERROR && (
                <div
                  className="error-container"
                  style={{ height: "calc(100vh - 100px)" }}
                >
                  <Title level={4}>
                    unfortunately an error has occurred please try again later.
                    will connect you shortly.
                  </Title>
                </div>
              )}
            </Col>
          </Row>
        </div>
        {/* reports-content */}
        {/* reports-footer */}
        <footer className="General-Footer">
          <Pagination
            showQuickJumper
            defaultCurrent={1}
            current={primaryPageNumber}
            total={report && report.count}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} `}
            pageSize={100}
            showSizeChanger={false}
            onChange={(pageNumber) => this.handlePrimaryPageChange(pageNumber)}
          />
        </footer>
        {/* reports-footer */}
        {this.state.drawersVisibility.createReport && (
          <CreateReport
            visible={this.state.drawersVisibility.createReport}
            onCloseDrawer={() =>
              this.onCloseDrawer(expandRecord === false ? markId : expandRecord)
            }
            customReportData={customReportData}
            disablePDF={changeSwitch === "ipIndia" ? true : false}
          />
        )}
        <Drawer
          title={<h2>Custom Report Details</h2>}
          placement="right"
          width={400}
          closable={false}
          open={
            this.state.drawersVisibility.addToReport
              ? this.state.drawersVisibility.addToReport
              : false
          }
          onClose={() =>
            this.changeStateValue_L1("drawersVisibility", "addToReport", false)
          }
        >
          <form>
            {this.props.reportAvailabilityStatus === SUCCESS &&
              this.props.reportAvailability &&
              this.props.reportAvailability.id && (
                <>
                  Choose an action:
                  <br />
                  <Radio.Group
                    value={
                      this.state?.customReportData[changeSwitch]?.operation
                    }
                    onChange={(event) =>
                      this.changeStateValue_L2(
                        "customReportData",
                        changeSwitch,
                        "operation",
                        event.target.value,
                      )
                    }
                  >
                    <Radio value="APPEND">Add marks to existing report</Radio>
                    <br />
                    <Radio value="CLEAR">
                      Remove previous marks from the report
                    </Radio>
                  </Radio.Group>
                  <br />
                  <br />
                </>
              )}
            Choose your preferred report type:
            <br />
            <Radio.Group
              value={this.state?.customReportData[changeSwitch]?.report_type}
              onChange={(event) =>
                this.changeStateValue_L2(
                  "customReportData",
                  changeSwitch,
                  "report_type",
                  event.target.value,
                )
              }
            >
              <Radio value="URGENT">Urgent Report</Radio>
              <br />
              <Radio value="REALTIME">Realtime report</Radio>
            </Radio.Group>
            <br />
            <br />
            <Alert
              type="warning"
              message={
                <div>
                  Note:
                  <br />
                  <strong>1.</strong> We recommend that you do the "Realtime
                  report" to ensure all information of selected marks are
                  realtime.
                  <br />
                  <strong>2.</strong> If the "Confirm" button is disabled then
                  the trademark report is under process. Please wait till it
                  becomes available.
                </div>
              }
            />
            <br />
            <br />
            <Button
              type="primary"
              ghost
              rootStyle={{ marginRight: "10px" }}
              disabled={
                this.props.reportAvailabilityStatus !== SUCCESS ||
                (this.props.reportAvailability &&
                  this.props.reportAvailability.id &&
                  this.props.reportAvailability.available === false)
              }
              onClick={() => this.handleConfirmReport_Debounced()}
            >
              Confirm
            </Button>
            <Button
              danger
              ghost
              onClick={() =>
                this.changeStateValue_L1(
                  "drawersVisibility",
                  "addToReport",
                  false,
                )
              }
            >
              Cancel
            </Button>
          </form>
        </Drawer>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getReport,
  getReportDetail,
  createReport,
  updateReportMark,
  deleteReport,
  getTrademarkReportStatus,
  postReport,
  patchTrademarkReport,
  logOut,
};

function mapStateToProps(state) {
  const {
    markReport,
    markReportStatus,
    markReportAppliedFilters,
    markReportAppliedSorting,
    markReportPageNumber,

    proprietorReport,
    proprietorReportStatus,
    proprietorReportAppliedFilters,
    proprietorReportAppliedSorting,
    proprietorReportPageNumber,

    ipIndiaReport,
    ipIndiaReportStatus,
    ipIndiaReportAppliedFilters,
    ipIndiaReportAppliedSorting,
    ipIndiaReportPageNumber,

    imageSearchReport,
    imageSearchReportStatus,
    imageSearchReportAppliedFilters,
    imageSearchReportAppliedSorting,
    imageSearchReportPageNumber,

    markReportDetail,
    markReportDetailStatus,
    markReportDetailAppliedFilters,
    markReportDetailAppliedSorting,
    markReportDetailPageNumber,

    proprietorReportDetail,
    proprietorReportDetailStatus,
    proprietorReportDetailAppliedFilters,
    proprietorReportDetailAppliedSorting,
    proprietorReportDetailPageNumber,

    ipIndiaReportDetail,
    ipIndiaReportDetailStatus,
    ipIndiaReportDetailAppliedFilters,
    ipIndiaReportDetailAppliedSorting,
    ipIndiaReportDetailPageNumber,

    imageSearchReportDetail,
    imageSearchReportDetailStatus,
    imageSearchReportDetailAppliedFilters,
    imageSearchReportDetailAppliedSorting,
    imageSearchReportDetailPageNumber,

    createSearchReport,
    createSearchReportStatus,

    updateReportMark,
    updateReportMarkMessage,
    updateReportMarkStatus,

    deleteReportMessage,
    deleteReportStatus,

    reportAvailabilityStatus,
    reportAvailability,
  } = state.searchState.report;
  return deepFreeze({
    userSubscriptions: state.userSubscriptions.userSubscriptions,

    markReport,
    markReportStatus,
    markReportAppliedFilters,
    markReportAppliedSorting,
    markReportPageNumber,

    proprietorReport,
    proprietorReportStatus,
    proprietorReportAppliedFilters,
    proprietorReportAppliedSorting,
    proprietorReportPageNumber,

    ipIndiaReport,
    ipIndiaReportStatus,
    ipIndiaReportAppliedFilters,
    ipIndiaReportAppliedSorting,
    ipIndiaReportPageNumber,

    imageSearchReport,
    imageSearchReportStatus,
    imageSearchReportAppliedFilters,
    imageSearchReportAppliedSorting,
    imageSearchReportPageNumber,

    markReportDetail,
    markReportDetailStatus,
    markReportDetailAppliedFilters,
    markReportDetailAppliedSorting,
    markReportDetailPageNumber,

    proprietorReportDetail,
    proprietorReportDetailStatus,
    proprietorReportDetailAppliedFilters,
    proprietorReportDetailAppliedSorting,
    proprietorReportDetailPageNumber,

    ipIndiaReportDetail,
    ipIndiaReportDetailStatus,
    ipIndiaReportDetailAppliedFilters,
    ipIndiaReportDetailAppliedSorting,
    ipIndiaReportDetailPageNumber,

    imageSearchReportDetail,
    imageSearchReportDetailStatus,
    imageSearchReportDetailAppliedFilters,
    imageSearchReportDetailAppliedSorting,
    imageSearchReportDetailPageNumber,

    createSearchReport,
    createSearchReportStatus,

    updateReportMark,
    updateReportMarkMessage,
    updateReportMarkStatus,

    deleteReportMessage,
    deleteReportStatus,

    reportAvailabilityStatus,
    reportAvailability,
    searchAlert: state.alertMessages.search,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportManagement);
