import { ROOT_URL } from "../../../configs/rootConfigs";
import { BC_UPLOAD_DATA } from "./types";
import mikeAxios from "../../../configs/mikeAxios";

//--------IP-SUITE ACTIONS--------
// import { checkAuthorisation } from "../../commonActions/actions";

import {
  LOADING,
  ERROR,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";
import {
  default_filter_page,
  default_filter_page_size,
} from "../../../components/Watch/watchFilter/utils/utils";
import { onResponseError } from "../../../functions/commonFunctions/interceptor";

export function getFilters(
  threatFlag,
  filter_type,
  search_term = null,
  page = default_filter_page,
  page_size = default_filter_page_size,
) {
  return async (dispatch) => {
    dispatch({
      type: `B_C_${filter_type}`,
      status: LOADING,
      filter_key: filter_type,
    });
    try {
      var params = {
        threat_flag: threatFlag,
        page,
        page_size,
        filter_type,
        search_term,
      };

      let response = await mikeAxios.get(
        `${ROOT_URL}/brand_compare/manage/filters/`,
        {
          params,
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );

      dispatch({
        type: `B_C_${filter_type}`,
        status: SUCCESS,
        payload: response?.data,
        filter_key: filter_type,
        page: page,
      });
    } catch (error) {
      onResponseError(error);
      dispatch({
        type: `B_C_${filter_type}`,
        status: ERROR,
        payload: error,
        filter_key: filter_type,
      });
    }
  };
}

export function getData(threatFlag, pageNumber, filterFlag, filters) {
  return async (dispatch) => {
    dispatch({ type: BC_UPLOAD_DATA, status: LOADING });
    try {
      const params = {
        page: pageNumber,
        page_size: 100,
      };
      let body = {
        threat_flag: threatFlag,
        filter_flag: filterFlag,
      };
      if (filterFlag) {
        body = {
          ...body,
          filters,
        };
      }
      let response = await mikeAxios.put(
        `${ROOT_URL}/brand_compare/manage/dashboard/`,
        body,
        {
          params: params,
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );

      dispatch({
        type: BC_UPLOAD_DATA,
        status: SUCCESS,
        payload: response?.data,
        pageNumber,
      });
    } catch (error) {
      onResponseError(error);
      dispatch({
        type: BC_UPLOAD_DATA,
        status: ERROR,
        payload: error,
      });
    }
  };
}
