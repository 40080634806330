import React from "react";
import {
  Dropdown,
  Button,
  Space,
  Select,
  Tooltip,
  Divider,
  Spin,
} from "antd";
import { DownOutlined, StopOutlined } from "@ant-design/icons";

const { Option } = Select;

const CommonSelectWithExcludeFilter = ({
  filteredData,
  value = [],
  loading = false,
  tableView = false,
  rename = "",
  extraHighlight = false,
  handleChange,
  handleSearch,
  handleScroll,
  filterId,
  showCountOnRight = false,
  exclude = [],
  handleExcludeButton,
  extraChildren,
  hideExclude = false,
}) => {
  const [openFilter, setOpenFilter] = React.useState(
    filteredData?.open ?? false,
  );

  const handleOpenChange = (event) => {
    setOpenFilter(event);
  };

  React.useEffect(() => {
    if (tableView) {
      handleOpenChange(true);
    }
  }, [tableView]);

  const placeholder = `Search ${rename ? rename : filteredData.menu_label}`;

  const tagRender = () => null;

  const selectMenu = () => (
    <Select
      mode="multiple"
      style={{ minWidth: "320px" }}
      dropdownStyle={{ minWidth: "320px" }}
      id={filterId ? filterId : `filter_id_${filteredData.key}`}
      onPopupScroll={handleScroll}
      loading={loading}
      placeholder={placeholder}
      open={openFilter}
      value={value}
      onChange={handleChange}
      showSearch
      searchValue={filteredData.searchValue}
      onSearch={handleSearch}
      className="select-filter-dropdown"
      optionFilterProp="children"
      filterOption={(input, option) =>
        String(option.label).toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      tagRender={tagRender}
      dropdownRender={(menu) => (
        <Spin spinning={loading}>
          {menu}
          {extraChildren && (
            <React.Fragment>
              <Divider style={{ margin: "8px 0" }} />
              {extraChildren}
            </React.Fragment>
          )}
        </Spin>
      )}
    >
      {filteredData.data.map((item, index) => {
        const isExcluded = exclude.some(
          (excludedItem) => excludedItem.value === item.value,
        );
        const isSelected = value.some(
          (selectedItem) => selectedItem.value === item.value,
        );
        return (
          <Option
            value={item.value}
            label={item.label}
            disabled={isExcluded}
            key={item.value}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {!hideExclude && (
                <Button
                  type="link"
                  disabled={isSelected}
                  onClick={(event) => handleExcludeButton(event, item)}
                  style={{
                    color: isExcluded ? "#ff0000" : "",
                    paddingLeft: "0px",
                  }}
                >
                  <Tooltip
                    placement="left"
                    title={isExcluded || isSelected ? "" : "Exclude"}
                  >
                    <StopOutlined />
                  </Tooltip>
                </Button>
              )}
              <span
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.label}
              </span>
              {showCountOnRight ? (
                <span
                  className="make-it-extreme-right"
                  style={{
                    marginLeft: "auto",
                    paddingRight: "10px",
                    color: "#929292",
                  }}
                >
                  ( {item?.metadata?.count ?? 0} )
                </span>
              ) : null}
            </div>
          </Option>
        );
      })}
    </Select>
  );

  return tableView ? (
    selectMenu()
  ) : (
    <Dropdown
      dropdownRender={() => selectMenu()}
      open={openFilter}
      onOpenChange={(event) => handleOpenChange(event)}
      selectable
      multiple={filteredData.multiple ? true : false}
    >
      <Button
        className={`${
          value.length || extraHighlight
            ? "Filters-Button-Selected"
            : "Filters-Button-Empty"
        }`}
        style={{ background: "inherit" }}
        // loading={loading}
      >
        <Space>
          {rename ? rename : filteredData.menu_label}
          <DownOutlined style={{ marginTop: "10px" }} />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default CommonSelectWithExcludeFilter;
