import {
  ERROR,
  LOADING,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";
import { allClassesObject } from "../../../constants/searchConstants/generalConstants";
import {
  GET_SEARCH_CLASS_FILTERS,
  GET_SEARCH_PROPRIETOR_FILTERS,
  GET_SEARCH_STATE_FILTERS,
  GET_SEARCH_STATUS_FILTERS,
  GET_SEARCH_TYPE_FILTERS,
  UPDATE_SEARCH_ACTIVE_INACTIVE_FILTERS,
  UPDATE_SEARCH_FILTER_APPLY_SEARCH_INPUT,
  UPDATE_SEARCH_FILTER_APPLY_SEARCH_TYPE,
  UPDATE_SEARCH_FILTER_DATE_PROPOSED,
  UPDATE_SEARCH_FILTER_DATE_VALUES,
  UPDATE_SEARCH_FILTER_EXCLUDED_VALUES,
  UPDATE_SEARCH_FILTER_INPUT_VALUE,
  UPDATE_SEARCH_FILTER_RESET_ALL,
  UPDATE_SEARCH_FILTER_SELECTED_VALUES,
  UPDATE_TRADEMARK_SEARCH_FILTER_APPLIED,
  UPDATE_SEARCH_FILTER_DATE_UNSTRUCTURED_DATE_OF_APPLIED,
  UPDATE_SEARCH_FILTER_DATE_UNSTRUCTURED_DATE_OF_USAGE
} from "../../actions/searchActions/types";

const defaultFilterConfig = {
  classConfig: {
    key: "SEARCH_CLASS",
    menu_label: "Classes",
    // data: [],
    data: allClassesObject,
    AndOrButton: {
      show: false,
    },
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    onlyValues: [],
    excludeValues: [],
    onlyExcludedValues: [],
    searchValue: "",
    isFiltersRendered: false,
  },
  statusConfig: {
    key: "SEARCH_STATUS",
    menu_label: "Status",
    data: [],
    AndOrButton: {
      show: false,
    },
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    onlyValues: [],
    excludeValues: [],
    onlyExcludedValues: [],
    searchValue: "",
    isFiltersRendered: false,
    activeStatus: false,
    inActiveStatus: false,
  },
  stateConfig: {
    key: "SEARCH_STATE",
    menu_label: "State",
    data: [],
    AndOrButton: {
      show: false,
    },
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    onlyValues: [],
    excludeValues: [],
    onlyExcludedValues: [],
    searchValue: "",
    isFiltersRendered: false,
  },
  proprietorConfig: {
    key: "SEARCH_PROPRIETOR",
    menu_label: "Proprietor",
    data: [],
    AndOrButton: {
      show: false,
    },
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    onlyValues: [],
    excludeValues: [],
    onlyExcludedValues: [],
    searchValue: "",
    isFiltersRendered: false,
  },
  typeConfig: {
    key: "SEARCH_TYPE",
    menu_label: "Type",
    data: [],
    AndOrButton: {
      show: false,
    },
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    onlyValues: [],
    excludeValues: [],
    onlyExcludedValues: [],
    searchValue: "",
    isFiltersRendered: false,
  },
  dateOfApplicationConfig: {
    key: "SEARCH_DATE_OF_APPLICATION",
    filter_is_unstructured_application_date: false,
    value: {},
  },
  dateOfUsageConfig: {
    key: "SEARCH_DATE_OF_USAGE",
    dateOfUsageProposed: false,
    filter_is_unstructured_date_of_usage: false,
    value: {},
  },
  reduxSearchValue: "",
  applicationSearchValue: "",
  descriptionSearchValue: "",
  reduxSearchType: "Application No/Name",
  isFilterApplied: false,
};

const generateUniqueArrayOfObject = (arrayOfObjects) => {
  const uniqueArray = [];
  const seenValues = new Set();

  for (const obj of arrayOfObjects) {
    const value = obj.value;
    if (!seenValues.has(value)) {
      uniqueArray.push(obj);
      seenValues.add(value);
    }
  }
  return uniqueArray;
};

export default function searchFilterReducer(
  state = defaultFilterConfig,
  action,
) {
  switch (action.type) {
    case GET_SEARCH_CLASS_FILTERS:
    case GET_SEARCH_STATUS_FILTERS:
    case GET_SEARCH_STATE_FILTERS:
    case GET_SEARCH_PROPRIETOR_FILTERS:
    case GET_SEARCH_TYPE_FILTERS: {
      const changedKey =
        Object.keys(state).find(
          (key) => state[key].key === `SEARCH_${action.filter_key}`,
        ) ?? "";

      if (changedKey) {
        const { status, page, payload } = action;
        const currentState = state[changedKey];

        switch (status) {
          case SUCCESS:
            return {
              ...state,
              [changedKey]: {
                ...currentState,
                data:
                  page > 1
                    ? generateUniqueArrayOfObject([
                        ...currentState.data,
                        ...payload.data,
                      ])
                    : payload.data,
                loading: false,
                metadata: {
                  ...currentState.metadata,
                  count: action.payload.metadata.count,
                  page: action?.page ?? 1,
                },
                isFiltersRendered: true,
              },
            };

          case LOADING:
            return {
              ...state,
              [changedKey]: {
                ...currentState,
                loading: true,
              },
            };

          case ERROR:
            return {
              ...state,
              [changedKey]: {
                ...currentState,
                loading: false,
              },
            };

          default:
            return state;
        }
      }
      return state;
    }

    case UPDATE_SEARCH_FILTER_SELECTED_VALUES: {
      const changedKey =
        Object.keys(state).find(
          (key) => state[key].key === `${action.filter_key}`,
        ) ?? "";
      const currentState = state[changedKey];
      return {
        ...state,
        [changedKey]: {
          ...currentState,
          selectedValues: action.values,
          onlyValues: action.onlyValues,
        },
      };
    }

    case UPDATE_SEARCH_FILTER_EXCLUDED_VALUES: {
      const changedKey = Object.keys(state).find(
        (key) => state[key].key === `${action.filter_key}`,
      );
      const currentState = state[changedKey];
      return {
        ...state,
        [changedKey]: {
          ...currentState,
          excludeValues: action.values,
          onlyExcludedValues: action.onlyValues,
        },
      };
    }

    case UPDATE_SEARCH_FILTER_DATE_VALUES: {
      const changedKey = Object.keys(state).find(
        (key) => state[key].key === `${action.filter_key}`,
      );
      const currentState = state[changedKey];

      return {
        ...state,
        [changedKey]: {
          ...currentState,
          value: action.values,
        },
      };
    }

    case UPDATE_SEARCH_FILTER_DATE_PROPOSED: {
      const changedKey = Object.keys(state).find(
        (key) => state[key].key === `${action.filter_key}`,
      );
      const currentState = state[changedKey];
      return {
        ...state,
        [changedKey]: {
          ...currentState,
          dateOfUsageProposed: action.values,
        },
      };
    }

    case UPDATE_SEARCH_FILTER_DATE_UNSTRUCTURED_DATE_OF_APPLIED: {
      const changedKey = Object.keys(state).find(
        (key) => state[key].key === `${action.filter_key}`,
      );
      const currentState = state[changedKey];
      return {
        ...state,
        [changedKey]: {
          ...currentState,
          filter_is_unstructured_application_date: action.values,
        },
      };
    }

    case UPDATE_SEARCH_FILTER_DATE_UNSTRUCTURED_DATE_OF_USAGE: {
      const changedKey = Object.keys(state).find(
        (key) => state[key].key === `${action.filter_key}`,
      );
      const currentState = state[changedKey];
      return {
        ...state,
        [changedKey]: {
          ...currentState,
          filter_is_unstructured_date_of_usage: action.values,
        },
      };
    }

    case UPDATE_TRADEMARK_SEARCH_FILTER_APPLIED: {
      return {
        ...state,
        isFilterApplied: action.value,
      };
    }

    case UPDATE_SEARCH_FILTER_RESET_ALL: {
      return {
        classConfig: {
          ...state.classConfig,
          AndOrButton: {
            show: false,
          },
          loading: false,
          selectedValues: [],
          onlyValues: [],
          excludeValues: [],
          onlyExcludedValues: [],
          searchValue: "",
          isFiltersRendered: false,
        },
        statusConfig: {
          ...state.statusConfig,
          AndOrButton: {
            show: false,
          },
          loading: false,
          selectedValues: [],
          onlyValues: [],
          excludeValues: [],
          onlyExcludedValues: [],
          searchValue: "",
          isFiltersRendered: false,
          activeStatus: false,
          inActiveStatus: false,
        },
        stateConfig: {
          ...state.stateConfig,
          AndOrButton: {
            show: false,
          },
          loading: false,
          selectedValues: [],
          onlyValues: [],
          excludeValues: [],
          onlyExcludedValues: [],
          searchValue: "",
          isFiltersRendered: false,
        },
        proprietorConfig: {
          ...state.proprietorConfig,
          AndOrButton: {
            show: false,
          },
          loading: false,
          selectedValues: [],
          onlyValues: [],
          excludeValues: [],
          onlyExcludedValues: [],
          searchValue: "",
          isFiltersRendered: false,
        },
        typeConfig: {
          ...state.typeConfig,
          AndOrButton: {
            show: false,
          },
          loading: false,
          selectedValues: [],
          onlyValues: [],
          excludeValues: [],
          onlyExcludedValues: [],
          searchValue: "",
          isFiltersRendered: false,
        },
        dateOfApplicationConfig: {
          ...state.dateOfApplicationConfig,
          value: {},
          filter_is_unstructured_application_date: false
        },
        dateOfUsageConfig: {
          ...state.dateOfUsageConfig,
          dateOfUsageProposed: false,
          filter_is_unstructured_date_of_usage: false,
          value: {},
        },
        reduxSearchValue: "",
        applicationSearchValue: "",
        descriptionSearchValue: "",
        reduxSearchType: "Application No/Name",
        isFilterApplied: false,
      };
    }

    case UPDATE_SEARCH_FILTER_INPUT_VALUE: {
      const changedKey = Object.keys(state).find(
        (key) => state[key].key === `${action.filter_key}`,
      );
      const currentState = state[changedKey];
      return {
        ...state,
        [changedKey]: {
          ...currentState,
          searchValue: action.value,
        },
      };
    }

    case UPDATE_SEARCH_ACTIVE_INACTIVE_FILTERS: {
      const changedKey = Object.keys(state).find(
        (key) => state[key].key === `${action.filter_key}`,
      );
      const currentState = state[changedKey];
      return {
        ...state,
        [changedKey]: {
          ...currentState,
          [action.activeOrInActiveKey]: action.value,
        },
      };
    }

    case UPDATE_SEARCH_FILTER_APPLY_SEARCH_INPUT: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }

    case UPDATE_SEARCH_FILTER_APPLY_SEARCH_TYPE: {
      return {
        ...state,
        reduxSearchType: action.value,
      };
    }

    default:
      return state;
  }
}
