import React from "react";
import CommonDateFilter from "../filters/CommonDateFilter";
import {
  updateSearchFilterDateProposedValues,
  updateSearchFilterDateUnStructuredDateOfAppliedValues,
  updateSearchFilterDateUnStructuredDateOfUsageValues,
  updateSearchFilterDateValues,
} from "../../../redux/actions/searchActions/searchFiltersActions";
import { useDispatch } from "react-redux";

const DateFilterContainer = (props) => {
  const dispatch = useDispatch();

  const handleDateChange = (filter_key, value) => {
    dispatch(updateSearchFilterDateValues(filter_key, value));
  };

  const handleProposedChange = (filter_key, value) => {
    dispatch(updateSearchFilterDateProposedValues(filter_key, value));
  };

  const handleUnStructureDateOfAppliedChange = (filter_key, value) => {
    dispatch(updateSearchFilterDateUnStructuredDateOfAppliedValues(filter_key, value));
  };

  const handleUnStructureDateOfUsageChange = (filter_key, value) => {
    dispatch(updateSearchFilterDateUnStructuredDateOfUsageValues(filter_key, value));
  };

  return (
    <CommonDateFilter
      {...props}
      handleDateChange={handleDateChange}
      handleProposedChange={handleProposedChange}
      handleUnStructureDOAChange={handleUnStructureDateOfAppliedChange}
      handleUnStructureDOUChange={handleUnStructureDateOfUsageChange}
    />
  );
};

export default DateFilterContainer;
