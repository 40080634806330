import { ROOT_URL } from "../../../configs/rootConfigs";
import {
  W_CHANGE_LIST_VIEW,
  W_COMPARE_MARKS,
  W_INSERT_SAMPLE_DATA,
  W_LIST_GET_BOOKMARK,
  W_LIST_GET_DATA,
  W_LIST_PATCH_BOOKMARK,
} from "./types";
import {
  LOADING,
  ERROR,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";
import { onResponseError } from "../../../functions/commonFunctions/interceptor";
import { checkAuthorisation } from "../commonActions/actions";
import { APPLIED_LIST_LAYOUT, BOOKMARK_TEMPLATE_TYPE } from "./types";
import { notification } from "antd";
import mikeAxios from "../../../configs/mikeAxios";

export function getListLayoutData(
  journalNumber,
  pageNumber,
  filterFlag,
  filters,
  journalChange = false,
) {
  const urlKey = window.location.pathname.split("/")[2] || "";
  const layout = window.location.pathname.split("/")[3] ?? "";

  const listUrl = `${ROOT_URL}/watch/${
    urlKey === "tm_applied" ? "applied" : urlKey
  }/${
    urlKey === "journals"
      ? "dashboard"
      : urlKey === "brand_compare"
        ? "list_layout/dashboard"
        : "list_layout"
  }/`;

  return async (dispatch) => {
    dispatch({ type: W_LIST_GET_DATA, status: LOADING, urlKey, layout });
    try {
      const params = {
        page: pageNumber,
        page_size: 100,
      };
      let body = {
        ...(urlKey === "tm_protect"
          ? { cease_desist_report_id: journalNumber }
          : { journal_number: journalNumber }),
        filter_flag: filterFlag,
      };
      if (filterFlag) {
        if (urlKey === "journals") {
          delete filters.threat_similarity;
        }
        body = {
          ...body,
          filters,
        };
      }
      if (urlKey === "brand_compare") {
        delete body.journal_number;
        body.analysis_id = journalNumber;
      }
      let response = await mikeAxios.put(`${listUrl}`, body, {
        params: params,
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });

      dispatch({
        type: W_LIST_GET_DATA,
        status: SUCCESS,
        payload: response?.data,
        journalNumber,
        pageNumber,
        urlKey,
        layout,
      });
    } catch (error) {
      // onResponseError(error);
      checkAuthorisation(dispatch, error);
      dispatch({
        type: W_LIST_GET_DATA,
        status: ERROR,
        payload: error,
        urlKey,
        layout,
      });
    }
  };
}

//bookmark for list layout only
export function patchListBookmark(body, template_key = APPLIED_LIST_LAYOUT) {
  return async (dispatch) => {
    let urlKey = window.location.pathname.split("/")[2] ?? "";
    const layout = window.location.pathname.split("/")[3] ?? "";

    if (urlKey === "tm_protect") {
      body = { ...body, cease_desist_report_id: body?.journal_number };
      delete body?.journal_number;
    }

    if (urlKey === "brand_compare") {
      body = { ...body, analysis_id: body?.journal_number };
      delete body?.journal_number;
    }

    dispatch({ type: W_LIST_PATCH_BOOKMARK, status: LOADING, urlKey, layout });
    try {
      let response = await mikeAxios.patch(
        `${ROOT_URL}/watch/filter_template/`,
        {
          ...body,
          template_type: BOOKMARK_TEMPLATE_TYPE,
          template_key,
          name: "sample",
        },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      notification["success"]({
        message: body?.template_json?.disable
          ? "Bookmark Deleted"
          : "Bookmark Created",
        duration: 2,
      });
      dispatch({
        type: W_LIST_PATCH_BOOKMARK,
        status: SUCCESS,
        payload: response?.data,
        urlKey,
        layout,
      });
    } catch (error) {
      onResponseError(error);
      notification["error"]({
        message: error,
      });
      dispatch({
        type: W_LIST_PATCH_BOOKMARK,
        status: ERROR,
        payload: error,
        urlKey,
        layout,
      });
    }
  };
}

export function getListBookmark(journal, template_key = APPLIED_LIST_LAYOUT) {
  return async (dispatch) => {
    let urlKey = window.location.pathname.split("/")[2] ?? "";
    const layout = window.location.pathname.split("/")[3] ?? "";

    dispatch({ type: W_LIST_GET_BOOKMARK, status: LOADING, urlKey, layout });
    try {
      var params = {
        template_key,
        template_type: BOOKMARK_TEMPLATE_TYPE,
        ...(urlKey === "tm_protect"
          ? { cease_desist_report_id: journal }
          : { journal_number: journal }),
      };

      if (urlKey === "brand_compare") {
        delete params.journal_number;
        params.analysis_id = journal;
      }

      let response = await mikeAxios.get(`${ROOT_URL}/watch/filter_template/`, {
        params,
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });

      dispatch({
        type: W_LIST_GET_BOOKMARK,
        status: SUCCESS,
        payload: response?.data,
        urlKey,
        layout,
      });
    } catch (error) {
      onResponseError(error);
      dispatch({
        type: W_LIST_GET_BOOKMARK,
        status: ERROR,
        payload: error,
        urlKey,
        layout,
      });
    }
  };
}

export function getCompareMarks(page, search_term) {
  return async (dispatch) => {
    const layout = window.location.pathname.split("/")[3];
    const urlKey = window.location.pathname.split("/")[2];

    dispatch({ type: W_COMPARE_MARKS, status: LOADING, layout, urlKey });
    try {
      var params = {
        page,
        page_size: 500,
        search_term,
      };

      let response = await mikeAxios.get(
        `${ROOT_URL}/watch/journals/dashboard/compare_marks`,
        {
          params,
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );

      dispatch({
        type: W_COMPARE_MARKS,
        status: SUCCESS,
        payload: response?.data,
        page: page,
        layout,
        urlKey,
      });
    } catch (error) {
      onResponseError(error);
      dispatch({
        type: W_COMPARE_MARKS,
        status: ERROR,
        payload: error,
        layout,
        urlKey,
      });
    }
  };
}

export function changeListDataView(view) {
  const layout = window.location.pathname.split("/")[3];
  const urlKey = window.location.pathname.split("/")[2];
  return async (dispatch) => {
    dispatch({
      type: W_CHANGE_LIST_VIEW,
      view: view,
      layout,
      urlKey,
    });
  };
}

export function addListSampleData(sampleObject) {
  const layout = window.location.pathname.split("/")[3];
  const urlKey = window.location.pathname.split("/")[2];
  return async (dispatch) => {
    dispatch({
      type: W_INSERT_SAMPLE_DATA,
      sampleObject,
      layout,
      urlKey,
    });
  };
}
