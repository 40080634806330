import React, { useState, useEffect } from "react";
import { Menu, Dropdown, Button, Row, Col, Input, Space } from "antd";
import { CaretDownFilled } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  changeSearchType,
  changeSearchValue,
} from "../../../../redux/actions/watchActions/watchActions";

const SearchTypeDropdown = ({
  value = "",
  setValue,
  searchValue = "",
  setSearchValue,
  listOfSearchType = [],
  updateRedux = false,
}) => {
  let urlKey = window.location.pathname.split("/")[2] || "";
  let dashboardLayout = window.location.pathname.split("/")[3] || "";
  const [localSearchValue, setLocalSearchValue] = useState(searchValue);
  const dispatch = useDispatch();
  const headerButton = {
    margin: "0px 5px 0px 0px",
    boxShadow: "none",
    border: "1px solid #1890ff",
    padding: "0px 5px",
    fontSize: "16px",
    color: "#1890ff",
    background: "#F5F6F8",
  };

  useEffect(() => {
    // to get value after using template
    setLocalSearchValue(searchValue);
  }, [searchValue]);

  const handleChangeSearchValue = (value)=>{
    setSearchValue(value);
    updateRedux && dispatch(changeSearchValue(value));
  }

  const headerButtonSelected = { ...headerButton, color: "#4285f4" };

  const handleChange = (event) => {
    setLocalSearchValue(event.target.value);
    handleChangeSearchValue(event.target.value)
  };

  const updatedPlaceholder =
    urlKey === "tm_protect" &&
    dashboardLayout === "grid_layout" &&
    listOfSearchType?.length === 1
      ? "Search Term"
      : value;

  const searchTypesMenu = () => (
    <Menu
      selectable
      selectedKeys={value}
      onClick={({ key }) => {
        setValue(key);
        setLocalSearchValue("");
        updateRedux && dispatch(changeSearchType(key));
        updateRedux && dispatch(changeSearchValue(""));
      }}
      style={{
        maxHeight: "200px",
        width: "250px",
        fontSize: "16px",
        overflow: "auto",
        boxShadow: "0px 0px 5px #c9c9c9",
      }}
    >
      {listOfSearchType.map((e) => (
        <Menu.Item key={e} style={{ fontSize: "16px" }}>
          {e}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
        <Space>
          {listOfSearchType?.length > 1 && (
            <Dropdown dropdownRender={() => searchTypesMenu()}>
              <Button style={headerButtonSelected}>
                {value}
                <CaretDownFilled />
              </Button>
            </Dropdown>
          )}
          <Input
            placeholder={updatedPlaceholder}
            onChange={(event) => handleChange(event)}
            value={localSearchValue}
            allowClear
            className="search-value-input-field"
            style={{
              width: value === "Application No/Name" ? 200 : 210,
              background: "#F5F6F8",
              borderRadius: "0px",
            }}
          />
        </Space>
  );
};

export default SearchTypeDropdown;
