//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import {
  Layout,
  Menu,
  Avatar,
  Tooltip,
  Badge,
  Modal,
  Alert,
  Spin,
  Skeleton,
  Button,
} from "antd";
import { Link } from "react-router-dom";

import {
  AppstoreOutlined,
  CopyOutlined,
  CopyrightCircleOutlined,
  DotChartOutlined,
  FileProtectOutlined,
  FolderOpenOutlined,
  GlobalOutlined,
  LayoutOutlined,
  LogoutOutlined,
  MailOutlined,
  OrderedListOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  SafetyCertificateOutlined,
  SettingOutlined,
  SmileOutlined,
  TableOutlined,
  UnorderedListOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

//--------IP-SUITE GENERAL FUNCTIONS--------
import { changeStateValue_L1 } from "../../../functions/commonFunctions/generalFunctions";

//--------IP-SUITE CONSTANTS--------
import { SUCCESS } from "../../../constants/commonConstants/generalConstants";

//--------IP-SUITE ACTIONS--------
import {
  logOut,
  getUserSubscriptions,
  getProfile,
  getAlertMessage,
} from "../../../redux/actions/commonActions/actions";

//--------IP-SUITE PROFILE-COMPONENTS--------
import UploadProfileLogo from "../../commonComponents/ProfileDetails/UploadProfileLogo";
//--------IP-SUITE COMMON COMPONENTS DRAWERS--------
import WhatsNew from "../../commonComponents/Drawers/Whats-New";

//--------IP-SUITE STYLE CONSTANTS--------
import { ipSuiteMenuStyle } from "../../../constants/commonConstants/styleConstants";
import { GET_WATCH_ALERT } from "../../../redux/actions/commonActions/types";

import "../../../stylesheets/searchStyleSheets/sidebar.css";
import ImageLabelIcon from "../../../assets/images/ImageLabel.svg?react";
import AaIcon from "../../../assets/images/Aa.svg?react";

import { routesArray } from "./Routes";
import * as Sentry from "@sentry/browser";
import Icon from "@ant-design/icons/lib/components/Icon";

import clock from "../../../assets/images/clock.svg";
import calendar from "../../../assets/images/calendar.svg";
import mixpanel from "mixpanel-browser";

const { Sider } = Layout;
const { SubMenu } = Menu;

class Watch extends Component {
  constructor(props) {
    super(props);
    let returningUser = localStorage.getItem("eventPopupSeen");

    this.state = {
      selectedKeys: [window.location.pathname],
      collapsed: false,
      modalsVisibility: {
        uploadLogo: false,
        CSSurvey: !(
          document.cookie &&
          document.cookie
            .split("; ")
            .find((row) => row.startsWith("Watch_CSSurvey"))
        ),
        suggestions: false,
        complaints: false,
        queriesSurvey: false,
        focusWordInfo: !(
          document.cookie &&
          document.cookie
            .split("; ")
            .find((row) => row.startsWith("Watch_focusWord"))
        ),
        imageRecognition: true,
      },
      drawersVisibility: {
        whatsNew: false,
      },

      eventModalVisible:
        returningUser !== undefined || returningUser !== null
          ? !returningUser
          : true,
    };

    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      if (this.props.userSubscriptions === null) {
        await this.props.getUserSubscriptions();

        if (this.props.userSubscriptionStatus === SUCCESS) {
          if (this.props.userSubscriptions.watch.length > 0) {
            this.props.getAlertMessage(GET_WATCH_ALERT, "watch");
            if (this.props.userProfile === null) {
              this.props.getProfile();
            }
          } else if (
            this.props.userSubscriptions.manager.length > 0 ||
            this.props.userSubscriptions.search.length > 0
          ) {
            this.props.history.push("/");
          } else {
            this.props.logOut();
          }
        }
      } else {
        if (this.props.userSubscriptions?.watch?.length > 0) {
          this.props.getAlertMessage(GET_WATCH_ALERT, "watch");
          if (this.props.userProfile === null) {
            this.props.getProfile();
          }
        } else if (
          this.props?.userSubscriptions?.includes("TRADEMARK") ||
          this.props?.userSubscriptions?.includes("PATENT")
        ) {
          this.props.history.push("/");
        } else {
          this.props.logOut();
        }
      }
    } else {
      this.props.logOut();
    }

    let returningUser = localStorage.getItem("eventPopupSeen");
    this.setState({ eventModalVisible: !returningUser });
  }

  handleClose = () => {
    localStorage.setItem("eventPopupSeen", true);
    this.setState({ eventModalVisible: false });
  };

  static getDerivedStateFromProps(props, state) {
    if (props?.userDetails?.name !== null && props?.userDetails?.email) {
      localStorage.setItem("userName", props?.userDetails?.name);
      localStorage.setItem("userEmail", props?.userDetails?.email);
      Sentry.setTag("userName", props?.userDetails?.name || "NA");
      Sentry.setTag("userEmail", props?.userDetails?.email || "NA");
    }
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  handleMenuClick(e) {
    this.setState({ selectedKeys: [e.key] });
  }

  render() {
    if (
      this.props.userSubscriptionStatus === "LOADING" ||
      this.props.userSubscriptionStatus === null
    ) {
      return (
        <div className="loading-spin">
          <Spin />
        </div>
      );
    }
    return (
      <>
        <Layout className="Watch">
          <Sider
            className="Sidebar"
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
          >
            <div
              className="Mikelegal"
              onClick={() => this.props.history.push("/")}
            >
              {this.state.collapsed ? "M" : "MikeLegal"}
            </div>
            {this.props.userProfile ? (
              <div
                style={{
                  display: `${this.state.collapsed ? "none" : ""}`,
                  textAlign: "center",
                  color: "#ffffff",
                }}
              >
                <Tooltip
                  placement="right"
                  title="Click here to Upload/Change logo"
                >
                  <Avatar
                    size={80}
                    shape="circle"
                    style={{ margin: "10px" }}
                    className="avatar-logo"
                    onClick={() =>
                      this.changeStateValue_L1(
                        "modalsVisibility",
                        "uploadLogo",
                        true,
                      )
                    }
                  >
                    {this.props.userProfile.picture ? (
                      <img
                        src={this.props.userProfile.picture}
                        style={{ width: "100%" }}
                        alt="User"
                      />
                    ) : (
                      <UserOutlined />
                    )}
                  </Avatar>
                </Tooltip>
                <div className="Navbar-Profile-Details">
                  {this.props.userProfile.name}
                </div>
                <div className="Navbar-Profile-Details">
                  {this.props.userProfile.email}
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: `${this.state.collapsed ? "none" : ""}`,
                  textAlign: "center",
                  color: "#ffffff",
                }}
              >
                <Skeleton />
              </div>
            )}
            <Menu
              theme="dark"
              mode="vertical"
              selectedKeys={this.state.selectedKeys}
              onClick={this.handleMenuClick}
              style={ipSuiteMenuStyle}
              className="vertical_scroll"
            >
              {this.props.userSubscriptions &&
                this.props.userSubscriptions.watch.includes(
                  "Journal Watch",
                ) && (
                  <SubMenu
                    key="tm_applied"
                    title={
                      <span>
                        <SafetyCertificateOutlined />
                        <span>TM Applied</span>
                      </span>
                    }
                  >
                    <Menu.Item
                      key="/watch/tm_applied/list_layout"
                      onClick={() =>
                        this.props.history.push("/watch/tm_applied/list_layout")
                      }
                    >
                      <span>
                        <UnorderedListOutlined />
                        <span>List Layout</span>
                      </span>
                    </Menu.Item>
                    <Menu.Item
                      key="/watch/tm_applied/grid_layout"
                      onClick={() =>
                        this.props.history.push("/watch/tm_applied/grid_layout")
                      }
                    >
                      <span>
                        <AppstoreOutlined />
                        <span>Grid Layout</span>
                      </span>
                    </Menu.Item>
                  </SubMenu>
                )}
              {this.props.userSubscriptions &&
                this.props.userSubscriptions.watch.includes("TM Protect") && (
                  <SubMenu
                    key="tm_protect"
                    title={
                      <span>
                        <FileProtectOutlined />
                        <span>TM Protect</span>
                      </span>
                    }
                  >
                    <Menu.Item
                      key="/watch/tm_protect/list_layout"
                      onClick={() =>
                        this.props.history.push("/watch/tm_protect/list_layout")
                      }
                    >
                      <span>
                        <UnorderedListOutlined />
                        <span>List Layout</span>
                      </span>
                    </Menu.Item>
                    <Menu.Item
                      key="/watch/tm_protect/grid_layout"
                      onClick={() =>
                        this.props.history.push("/watch/tm_protect/grid_layout")
                      }
                    >
                      <span>
                        <AppstoreOutlined />
                        <span>Grid Layout</span>
                      </span>
                    </Menu.Item>
                  </SubMenu>
                )}
              {/* {this.props.userSubscriptions &&
                this.props.userSubscriptions.watch.includes(
                  "IMAGE_RECOGNITION",
                ) && (
                  <SubMenu
                    key="image_text"
                    title={
                      <span>
                        <Icon component={AaIcon} />
                        <span>Image Text</span>{" "}
                        <sup style={{ color: "rgb(255, 0, 0)" }}>Old</sup>
                      </span>
                    }
                  >
                    <Menu.Item
                      key="/watch/image_text/list_layout"
                      onClick={() =>
                        this.props.history.push("/watch/image_text/list_layout")
                      }
                    >
                      <span>
                        <UnorderedListOutlined />
                        <span>List Layout</span>
                      </span>
                    </Menu.Item>
                    <Menu.Item
                      key="/watch/image_text/grid_layout"
                      onClick={() =>
                        this.props.history.push("/watch/image_text/grid_layout")
                      }
                    >
                      <span>
                        <AppstoreOutlined />
                        <span>Grid Layout</span>
                      </span>
                    </Menu.Item>
                  </SubMenu>
                )} */}
              {this.props.userSubscriptions &&
                this.props.userSubscriptions.watch.includes(
                  "IMAGE_RECOGNITION",
                ) && (
                  <SubMenu
                    key="image_text_new"
                    title={
                      <span>
                        <Icon component={AaIcon} />
                        <span>
                          Image Text{" "}
                          {/* <sup style={{ color: "rgb(235, 0, 19)" }}>New</sup> */}
                        </span>
                      </span>
                    }
                  >
                    <Menu.Item
                      key="/watch/image_text_new/list_layout"
                      onClick={() =>
                        this.props.history.push(
                          "/watch/image_text_new/list_layout",
                        )
                      }
                    >
                      <span>
                        <UnorderedListOutlined />
                        <span>List Layout</span>
                      </span>
                    </Menu.Item>
                    <Menu.Item
                      key="/watch/image_text_new/grid_layout"
                      onClick={() =>
                        this.props.history.push(
                          "/watch/image_text_new/grid_layout",
                        )
                      }
                    >
                      <span>
                        <UnorderedListOutlined />
                        <span>Grid Layout</span>
                      </span>
                    </Menu.Item>
                  </SubMenu>
                )}
              {/* {this.props.userSubscriptions &&
                this.props.userSubscriptions.watch.includes(
                  "IMAGE_RECOGNITION",
                ) && (
                  <SubMenu
                    key="image_label"
                    title={
                      <span>
                        <Icon component={ImageLabelIcon} />
                        {this.state.collapsed ? null : <span>Image Label</span>}
                      </span>
                    }
                  >
                    <Menu.Item
                      key="/watch/image_label/grid_layout"
                      onClick={() =>
                        this.props.history.push(
                          "/watch/image_label/grid_layout",
                        )
                      }
                    >
                      <span>
                        <AppstoreOutlined />
                        <span>Grid Layout</span>
                      </span>
                    </Menu.Item>
                  </SubMenu>
                )} */}
              {this.props.userSubscriptions &&
                this.props.userSubscriptions.watch.includes(
                  "IMAGE_RECOGNITION",
                ) && (
                  <SubMenu
                    key="image_similarity"
                    title={
                      <span>
                        <Icon component={ImageLabelIcon} />
                        {this.state.collapsed ? null : (
                          <Tooltip
                            title={
                              <span>
                                Image Similarity{" "}
                                <sup style={{ color: "yellow" }}>Beta</sup>
                              </span>
                            }
                          >
                            <span>Image Similarity</span>
                          </Tooltip>
                        )}
                      </span>
                    }
                  >
                    <Menu.Item
                      key="/watch/image_similarity/list_layout"
                      onClick={() =>
                        this.props.history.push(
                          "/watch/image_similarity/list_layout",
                        )
                      }
                    >
                      <span>
                        <AppstoreOutlined />
                        <span>List Layout</span>
                      </span>
                    </Menu.Item>
                    {/* <Menu.Item
                      key="/watch/image_similarity/grid_layout"
                      onClick={() =>
                        this.props.history.push(
                          "/watch/image_similarity/grid_layout",
                        )
                      }
                    >
                      <span>
                        <UnorderedListOutlined />
                        <span>Grid Layout</span>
                      </span>
                    </Menu.Item> */}
                  </SubMenu>
                )}
              {this.props.userSubscriptions &&
                this.props.userSubscriptions.watch.includes(
                  "BRAND_COMPARE",
                ) && (
                  <SubMenu
                    key="brand_compare"
                    title={
                      <span>
                        <CopyrightCircleOutlined />
                        <span>Brand Compare</span>
                      </span>
                    }
                  >
                    <Menu.Item
                      key="/watch/brand_compare/list_layout"
                      onClick={() =>
                        this.props.history.push(
                          "/watch/brand_compare/list_layout",
                        )
                      }
                    >
                      <span>
                        <UnorderedListOutlined />
                        <span>Dashboard</span>
                      </span>
                    </Menu.Item>
                    <Menu.Item
                      key="/watch/brand_compare/upload"
                      onClick={() =>
                        this.props.history.push("/watch/brand_compare/upload")
                      }
                    >
                      <span>
                        <SettingOutlined />
                        <span>Manage</span>
                      </span>
                    </Menu.Item>
                  </SubMenu>
                )}
              <SubMenu
                icon={<MailOutlined />}
                key="manage-emails"
                className="ipsuite-submenu-item"
                title={
                  <span>
                    <span>Manage Emails</span>
                  </span>
                }
              >
                <Menu.Item
                  icon={<CopyOutlined />}
                  key="/watch/email-template"
                  className="ipsuite-submenu-list"
                >
                  <Link to="/watch/email-template">
                    <span>Email Template</span>
                  </Link>
                </Menu.Item>
                <Menu.Item
                  icon={<TableOutlined />}
                  key="/watch/email-management"
                  className="ipsuite-submenu-list"
                >
                  <Link to="/watch/email-management">
                    <span>Client Emails</span>
                  </Link>
                </Menu.Item>

                <Menu.Item
                  icon={<SettingOutlined />}
                  key="/watch/email-preferences"
                  className="ipsuite-submenu-list"
                >
                  <Link to="/watch/email-preferences">
                    <span>Email Preferences</span>
                  </Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="reports"
                className="ipsuite-submenu-item"
                title={
                  <span>
                    <DotChartOutlined />
                    <span>Reports</span>
                  </span>
                }
              >
                <Menu.Item
                  icon={<FolderOpenOutlined />}
                  key="/watch/reports"
                  onClick={() => this.props.history.push("/watch/reports")}
                >
                  <span>Report Dashboard</span>
                </Menu.Item>
                <Menu.Item
                  key="/watch/client-email-report"
                  icon={<OrderedListOutlined />}
                  onClick={() =>
                    this.props.history.push("/watch/client-email-report")
                  }
                >
                  {/* <Link to="/watch/client-email-report"> */}
                  <span>Client Email</span>
                  {/* </Link> */}
                </Menu.Item>
              </SubMenu>
              <Menu.Item
                icon={<ReadOutlined />}
                key="/watch/journals/list_layout"
                onClick={() =>
                  this.props.history.push("/watch/journals/list_layout")
                }
              >
                <span>Journals</span>
              </Menu.Item>
              <Menu.Item
                icon={<UsergroupAddOutlined />}
                key="/watch/client-master"
                onClick={() => this.props.history.push("/watch/client-master")}
              >
                <span>Client Master</span>
              </Menu.Item>
              <Menu.Item
                icon={<UserOutlined />}
                key="/watch/profile"
                onClick={() => {
                  this.props.history.push("/watch/profile");
                }}
              >
                <span>Profile</span>
              </Menu.Item>
              <Menu.Item
                icon={<TableOutlined />}
                key="/watch/manage-trademarks"
                onClick={() =>
                  this.props.history.push("/watch/manage-trademarks")
                }
              >
                <span>Manage Trademarks</span>
              </Menu.Item>
              {/* <Menu.Item
              key="/watch/email-template"
              onClick={() => this.props.history.push("/watch/email-template")}
              className="ipsuite-menu-item"
            >
              <Icon type="mail" />
              <span>Email Template</span>
            </Menu.Item> */}
              <Menu.Item
                icon={
                  <GlobalOutlined
                    style={{
                      color:
                        this.state.selectedKeys[0] === "/watch/whats-new"
                          ? "#fff"
                          : "#bebebe",
                    }}
                  />
                }
                key="/watch/whats-new"
                onClick={() => {
                  this.changeStateValue_L1(
                    "drawersVisibility",
                    "whatsNew",
                    true,
                  );
                  document.cookie = "whatsNewWatch=false";
                }}
              >
                <Badge
                  dot={this.state.collapsed}
                  count={
                    document.cookie
                      .split("; ")
                      .find((row) => row.startsWith("whatsNewWatch"))
                      ? "0"
                      : "New"
                  }
                  style={{
                    top: `${this.state.collapsed ? "10px" : "7px"}`,
                    right: `${this.state.collapsed ? "-10px" : "-30px"}`,
                  }}
                >
                  <span
                    style={{
                      color:
                        this.state.selectedKeys[0] === "/watch/whats-new"
                          ? "#fff"
                          : "#bebebe",
                    }}
                  >
                    What's New
                  </span>
                </Badge>
              </Menu.Item>

              <Menu.Item
                key="/watch/faqs"
                className="ipsuite-menu-item"
                icon={<QuestionCircleOutlined />}
              >
                <Link to="/watch/faqs">
                  <span>FAQs</span>
                </Link>
              </Menu.Item>

              <SubMenu
                icon={<SmileOutlined />}
                key="feedbacks"
                title={
                  <span>
                    <span>Feedback</span>
                  </span>
                }
              >
                <Menu.Item
                  key="customer-satisfaction"
                  onClick={() =>
                    this.changeStateValue_L1(
                      "modalsVisibility",
                      "CSSurvey",
                      true,
                    )
                  }
                >
                  Customer Satisfaction
                </Menu.Item>
                <Menu.Item
                  key="suggestions"
                  onClick={() =>
                    this.changeStateValue_L1(
                      "modalsVisibility",
                      "suggestions",
                      true,
                    )
                  }
                >
                  Suggestions
                </Menu.Item>
                <Menu.Item
                  key="complaints"
                  onClick={() =>
                    this.changeStateValue_L1(
                      "modalsVisibility",
                      "complaints",
                      true,
                    )
                  }
                >
                  Complaints
                </Menu.Item>
                <Menu.Item
                  key="queries-survey"
                  onClick={() =>
                    this.changeStateValue_L1(
                      "modalsVisibility",
                      "queriesSurvey",
                      true,
                    )
                  }
                >
                  Queries
                </Menu.Item>
              </SubMenu>
              <Menu.Item key="logout" onClick={this.props.logOut}>
                <LogoutOutlined />
                <span>Log Out</span>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout>
            {this.props.watchAlert && (
              <Alert
                type="error"
                message={
                  <div
                    style={{ textAlign: "center" }}
                    dangerouslySetInnerHTML={{ __html: this.props.watchAlert }}
                  ></div>
                }
              />
            )}
            <Switch>
              {routesArray.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  component={route.component}
                />
              ))}
            </Switch>
          </Layout>
        </Layout>

        <UploadProfileLogo
          visible={this.state.modalsVisibility.uploadLogo}
          onCloseModal={() =>
            this.changeStateValue_L1("modalsVisibility", "uploadLogo", false)
          }
        />

        <WhatsNew
          visible={this.state.drawersVisibility.whatsNew}
          onCloseDrawer={() =>
            this.changeStateValue_L1("drawersVisibility", "whatsNew", false)
          }
          collapsed={this.state.collapsed}
        />

        {/* TM-Watch customer satisfaction survey. */}
        {/* <Modal
        title="Important - Focus Word Customization"
        visible={this.state.modalsVisibility.focusWordInfo}
        maskClosable={false}
        onCancel={() => {
          document.cookie = `Watch_focusWord=;max-age=${60 * 60 * 24 * 20}`;
          this.changeStateValue_L1(
            "modalsVisibility",
            "focusWordInfo",
            false
          );
        }}
        width={"800px"}
        footer={null}
      >
        <p>Greetings,</p>
        <br />
        <p>
          For the best trademark watch experience it is important for you to
          work on the focus words failing to which you will end up seeing
          infringements for a lot of generic and descriptive marks since we by
          default create focus words with each element of your mark.
        </p>
        <br />
        <p>In order to help you for the same, we would recommend – </p>
        <p>
          <strong>1.</strong> Download your portfolio with the focus words
          from the manage trademarks option.
        </p>
        <p>
          <strong>2.</strong> Remove any descriptive words like 'The ', 'and
          ', 'limited ' etc. These can be easily done using a search and
          replace all feature in excel. Do make sure that you add a space
          after the descriptive word so that only individual words get removed
          and not the part of the word.
        </p>
        <p>
          <strong>3.</strong> Remove focus words which you do not want to be
          watched.
        </p>
        <br />
        <p>
          Do let us know in case you need any more assistance. You can also
          let us know anything from feedback option in the sidebar.
        </p>
        <br />
        <p>Always there to help!</p>
        <p>Team MikeLegal</p>
      </Modal> */}

        {/* TM-Watch customer satisfaction survey. */}
        {/* <SurveyModal
                  title="customerSatisfactionWatch"
                  src="https://s.surveyplanet.com/c087mH2JB"
                  visible={this.state.modalsVisibility.CSSurvey}
                  maskClosable={false}
                  onCancel={() => {
                      document.cookie = `Watch_CSSurvey=;max-age=${60*60*24*20}`;
                      this.changeStateValue_L1("modalsVisibility", "CSSurvey", false);
                  }}
              /> */}

        {/* Suggestions survey. */}
        {/* <SurveyModal
                  title="suggestions"
                  src="https://s.surveyplanet.com/1eI4bBov0"
                  visible={this.state.modalsVisibility.suggestions}
                  maskClosable={true}
                  onCancel={() => this.changeStateValue_L1("modalsVisibility", "suggestions", false)}
              /> */}

        {/* Complaints survey */}
        {/* <SurveyModal
                  title="complaints"
                  src="https://s.surveyplanet.com/7597Miojp"
                  visible={this.state.modalsVisibility.complaints}
                  maskClosable={true}
                  onCancel={() => this.changeStateValue_L1("modalsVisibility", "complaints", false)}
              /> */}

        {/* Queries survey */}
        {/* <SurveyModal
                  title="queriesSurvey"
                  src="https://s.surveyplanet.com/JlqHxcfFh"
                  visible={this.state.modalsVisibility.queriesSurvey}
                  maskClosable={true}
                  onCancel={() => this.changeStateValue_L1("modalsVisibility", "queriesSurvey", false)}
              /> */}

        {/* Image Recognition */}
        {/* <ImageRecognition
                  visible={this.state.modalsVisibility.imageRecognition}
                  onCloseModal={() => this.changeStateValue_L1("modalsVisibility", "imageRecognition", false)}
              /> */}
        {/* <Modal
          title={<span className="event-popup-title">UPCOMING WEBINAR</span>}
          className="event-modal"
          open={this.state.eventModalVisible}
          onCancel={() => this.handleClose()}
          footer={[
            <Button key="back" onClick={() => this.handleClose()}>
              Skip for Now
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() =>
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLSeBZWM6SDTlebUiGpKv7CUF4mtxlwO5-GL4-DrkmQiERG1i-g/viewform",
                  "_blank",
                )
              }
            >
              I'm Interested
            </Button>,
          ]}
        >
          <h4
            style={{
              margin: "20px 0px",
              textTransform: "capitalize",
            }}
          >
            How legal teams use MikeTM Watch to automate the journal process and
            strengthen their brand protection strategy
          </h4>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0px 0px 16px 0px",
            }}
          >
            <span>
              <img src={clock} alt="clock" />
            </span>
            <span style={{ marginLeft: "8px" }}>4:00pm - 5:00pm IST </span>
            <span>
              <img src={clock} style={{ marginLeft: "16px" }} alt="calendar" />
            </span>
            <span style={{ marginLeft: "8px" }}>18th April 2024</span>
          </div>
          <p
            style={{
              fontWeight: "bold",
              margin: "24px 0px 4px 8px",
            }}
          >
            Our Co-founder, Tushar Bhargava will talk about:
          </p>
          <ul style={{ margin: "0px 0px 4px 16px" }}>
            <li className="dot-list">
              Using MikeTM Watch to detect infringements in the journal
              <ul style={{ margin: "0px 0px 4px 16px" }}>
                <li className="dot-list">On the basis of TM Applied For</li>
                <li className="dot-list">On the basis of Image Similarity</li>
              </ul>
            </li>
            <li className="dot-list">
              Customisations available to get targeted infringements
            </li>
            <li className="dot-list">
              Ability to search in the Goods and Services description
            </li>
            <li className="dot-list">
              Ways to protect brand before publication in journal
            </li>
            <li className="dot-list">Q&A session</li>
          </ul>
        </Modal> */}
      </>
    );
  }
}

const mapDispatchToProps = {
  getProfile,
  logOut,
  getUserSubscriptions,
  getAlertMessage,
};

function mapStateToProps(state) {
  const { userSubscriptions, userSubscriptionStatus, userDetails } =
    state.userSubscriptions;
  const { userProfile, userProfileStatus } = state.profile;
  return {
    userDetails,
    userSubscriptions,
    userSubscriptionStatus,
    userProfile,
    userProfileStatus,
    watchAlert: state.alertMessages.watch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Watch);
