import React, { useEffect, useState, useRef } from "react";
import { Dropdown, Button, Space, Checkbox } from "antd";
import { DownOutlined } from "@ant-design/icons";
import DateRangePicker from "../../../functions/commonFunctions/dateRangePicker";
import dayjs from "dayjs";

const CommonDateFilter = ({
  menu_label = "",
  subtitle1 = "",
  subtitle2 = "",
  value1 = { from: "", to: "", dateString: "" },
  value2 = { from: "", to: "", dateString: "" },
  proposed = false,
  tableView = false,
  filter_key_1 = "",
  filter_key_2 = "",
  handleDateChange,
  handleProposedChange,
  doaUnStructured = false,
  douUnStructured = false,
  handleUnStructureDOAChange,
  handleUnStructureDOUChange
}) => {
  const initialRange1 = useRef([
    value1.from ? dayjs(value1.from) : null,
    value1.to ? dayjs(value1.to) : null,
  ]);
  const initialRange2 = useRef([
    value2.from ? dayjs(value2.from) : null,
    value2.to ? dayjs(value2.to) : null,
  ]);

  const [range1, setRange1] = useState(initialRange1.current);
  const [range2, setRange2] = useState(initialRange2.current);
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    setRange1([
      value1?.from ? dayjs(value1.from) : "",
      value1?.to ? dayjs(value1.to) : "",
    ]);
    setRange2([
      value2?.from ? dayjs(value2.from) : "",
      value2?.to ? dayjs(value2.to) : "",
    ]);
  }, [value1, value2]);

  const handleDateStringUpdate =
    (filterKey, value, setRange) => (startValue, endValue, dateString) => {
      const updatedValue = {
        ...value,
        from: startValue,
        to: endValue,
        dateString: startValue || endValue ? dateString : null,
      };
      setRange([dayjs(startValue), dayjs(endValue)]);
      handleDateChange(filterKey, updatedValue);
    };

  const dateBox = () => (
    <>
      <div id="date-empty-div-id" style={{padding:'5px 10px'}}>
        <span style={{ fontWeight: "600" }}>{subtitle1}</span>
        <DateRangePicker
          startValue={value1.from}
          endValue={value1.to}
          setDate={(val) => setRange1(val)}
          range={range1}
          handleDateString={handleDateStringUpdate(
            filter_key_1,
            value1,
            setRange1,
          )}
        />
      </div>
      <div style={{padding:'5px 10px'}}>
        <span style={{ fontWeight: "600" }}>{subtitle2}</span>
        <DateRangePicker
          startValue={value2.from}
          endValue={value2.to}
          setDate={(val) => setRange2(val)}
          range={range2}
          id={`watch_filter_id_${filter_key_2 || "date_2"}`}
          handleDateString={handleDateStringUpdate(
            filter_key_2,
            value2,
            setRange2,
          )}
        />
        <div style={{ lineHeight: "32px",display:'flex',flexDirection:'column',marginTop:'5px'}} className="checkbox-custom-dropdown">
        <Checkbox
            checked={doaUnStructured}
            id="id_doaUnStructured-to-checkbox"
            tagName="tagname_doaUnStructured-to-checkbox"
            onChange={(event) =>
              handleUnStructureDOAChange(filter_key_1, event.target.checked)
            }
          >
            Unstructured DoA
          </Checkbox>
          <Checkbox
            checked={douUnStructured}
            id="id_douUnStructured-to-checkbox"
            tagName="tagname_douUnStructured-to-checkbox"
            onChange={(event) =>
              handleUnStructureDOUChange(filter_key_2, event.target.checked)
            }
          >
            Unstructured DoU
          </Checkbox>
          <Checkbox
            checked={proposed}
            id="id_proposed-to-checkbox"
            tagName="tagname_proposed-to-checkbox"
            onChange={(event) =>
              handleProposedChange(filter_key_2, event.target.checked)
            }
          >
            Proposed
          </Checkbox>
        </div>
      </div>
    </>
  );

  return tableView ? (
    dateBox()
  ) : (
    <Dropdown
      dropdownRender={dateBox}
      overlayClassName="Filters-Menu"
      open={openFilter}
      onOpenChange={setOpenFilter}
    >
      <Button
        className={`${
          value1.dateString || value2.dateString || proposed || doaUnStructured || douUnStructured
            ? "Filters-Button-Selected"
            : "Filters-Button-Empty"
        }`}
        style={{ background: "inherit" }}
      >
        <Space>
          {menu_label}
          <DownOutlined style={{ marginTop: "10px" }} />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default CommonDateFilter;
